import React, { useState,useRef } from "react";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup"; 
import Axios from "axios";
import { ToastContainer } from "react-toastify"; // Import ToastContainer and toast
import { toast } from "react-toastify";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import classNames from "classnames";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const EcheckAuthPayment = ({paid,object,amount,setActiveIndex,formik2}) => {
    let toast = useRef();
    const [formattedCardNumber, setFormattedCardNumber] = useState("");
    amount = parseFloat(amount).toFixed(2);
    const basicData = localStorage.getItem("prepaidbasicData");
    const parsebasicData = JSON.parse(basicData);
    const userDetails = parsebasicData?.data;
    const [isLoading, setIsLoading] = useState(false);
    const loginRes = localStorage.getItem("userData");
    const parseLoginRes = JSON.parse(loginRes);
    let checkLabel;
    try {
        const storedItem = localStorage.getItem("checkLabel");
        checkLabel = storedItem ? JSON.parse(storedItem) : null;
    } catch (error) {
        console.error("Error parsing checkLabel from localStorage", error);
        checkLabel = null;
    }
    const validationSchema = Yup.object().shape({
       
        totalAmount: Yup.string().required("Please Select Amount"),
        routingNumber: Yup.string().required("This is required"),
        AccountNumber: Yup.string().required("This is required"),

    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            totalAmount: "",
            amount: amount,
            routingNumber: "",
            AccountNumber: "",
            NameOnAccount: "",
            refId: "",
            accountId: userDetails?.accountId,
        },

        onSubmit: async (values, actions) => {

            setIsLoading(true)
            const dataToSend = {
                amount: formik.values.totalAmount,
                nameOnAccount: formik.values.NameOnAccount,
                routingNumber: formik.values.routingNumber,
                accountNumber: formik.values.AccountNumber,
                accountType: formik.values.accountType,
                accountId: formik.values.accountId,
                customerId:userDetails?._id, 
                userId: parseLoginRes?._id,
                invoiceId: "",
            };
            try {
                const response = await Axios.post(`${BASE_URL}/api/web/invoices/paymentEcheck`, dataToSend);
                if (response?.data?.data?.responseCode == "1")  {
                    
                    localStorage.setItem("paymentstatus", "paid");

                    //setActiveIndex(3);
                    let paymentproceed = localStorage.getItem("paymentstatus");

                    if (paymentproceed === "paid") {
                        let additionalFeature = [];
                        let discounts = [];

                        let devicepricing = JSON.parse(localStorage.getItem("devicepricing"));
                        let simpricing = JSON.parse(localStorage.getItem("simpricing"));
                        let dueDate = "";
                        let applyLateFee = "";
                        let oneTimeCharge = "";
                        let planName = "";
                        let planId = "";
                        let planCharges = "";
                        if (object?.billId === simpricing?._id) {
                            dueDate = simpricing?.dueDate;
                            oneTimeCharge = simpricing?.oneTimeCharge;
                            applyLateFee = simpricing?.applyLateFee;
                            let simselecteddiscounts = JSON.parse(localStorage.getItem("simdiscountobjectarray"));

                            for (let i = 0; i < simpricing?.selectdiscount?.length; i++) {
                                for (let k = 0; k < simselecteddiscounts.length; k++) {
                                    if (simselecteddiscounts[k] === simpricing?.selectdiscount[i]?._id) {
                                        let obj = {
                                            name: simpricing?.selectdiscount[i]?.discountname,
                                            amount: simpricing?.selectdiscount[i]?.amount,
                                        };
                                        discounts.push(obj);
                                    }
                                }
                            }
                            let plandata = JSON.parse(localStorage.getItem("planprices"));
                            for (let i = 0; i < plandata?.length; i++) {
                                if (object.plan === plandata[i]?._id) {
                                    planName = plandata[i]?.name;
                                    planCharges = plandata[i]?.price;

                                    planId = plandata[i]?._id;
                                }
                            }

                            let simadditional = JSON.parse(localStorage.getItem("simadditionalfeaturearray"));
                            for (let k = 0; k < simadditional?.length; k++) {
                                for (let i = 0; i < simpricing?.additionalFeature?.length; i++) {
                                    if (simpricing?.additionalFeature[i]?._id === simadditional[k]) {
                                        let obj = {
                                            name: simpricing?.additionalFeature[i]?.featureName,
                                            amount: simpricing?.additionalFeature[i]?.featureAmount,
                                        };
                                        additionalFeature.push(obj);
                                    }
                                }
                            }
                        } else {
                            let plandata = JSON.parse(localStorage.getItem("planprices"));
                            dueDate = devicepricing?.dueDate;
                            applyLateFee = devicepricing?.applyLateFee;
                            oneTimeCharge = devicepricing?.oneTimeCharge;
                            let deviceselecteddiscounts = JSON.parse(localStorage.getItem("devicediscountobjectarray"));

                            for (let i = 0; i < devicepricing?.selectdiscount?.length; i++) {
                                for (let k = 0; k < deviceselecteddiscounts.length; k++) {
                                    if (deviceselecteddiscounts[k] === devicepricing?.selectdiscount[i]?._id) {
                                        let obj = {
                                            name: devicepricing?.selectdiscount[i]?.discountname,
                                            amount: devicepricing?.selectdiscount[i]?.amount,
                                        };
                                        discounts.push(obj);
                                    }
                                }
                            }
                            for (let i = 0; i < plandata?.length; i++) {
                                if (object.plan === plandata[i]?._id) {
                                    planName = plandata[i]?.name;
                                    planCharges = plandata[i]?.price;

                                    planId = plandata[i]?._id;
                                }
                            }
                            let deviceadditional = JSON.parse(localStorage.getItem("deviceadditionalfeaturearray"));
                            for (let k = 0; k < deviceadditional?.length; k++) {
                                for (let i = 0; i < devicepricing?.additionalFeature?.length; i++) {
                                    if (deviceadditional[k] === devicepricing?.additionalFeature[i]?._id) {
                                        let obj = {
                                            name: devicepricing?.additionalFeature[i]?.featureName,
                                            amount: devicepricing?.additionalFeature[i]?.featureAmount,
                                        };
                                        additionalFeature.push(obj);
                                    }
                                }
                            }
                        }
                        let plan = object?.plan;
                        // let dateincasepart
                        //object.totalAmount === paid ? dueDate:"Partial"
                        const shipmentName = localStorage.getItem("shipmentName");
                        const shipmentCost = localStorage.getItem("shipmentCost");
                        // const checkLabel = JSON.parse(localStorage.getItem("checkLabel"));
                        let dataToSend = {
                            isAutopay: false,
                            customerId: object.customerid,
                            invoiceType: "Sign Up",
                            totalAmount: object.totalamount,
                            additionalCharges: additionalFeature,
                            discount: discounts,
                            amountPaid: formik.values.totalAmount,
                            selectProduct: object?.billId,
                            invoiceDueDate: dueDate,
                            lateFee: applyLateFee,
                            invoiceOneTimeCharges: oneTimeCharge,
                            invoiceStatus: object.totalamount <= parseFloat(formik.values.totalAmount) ? "Paid" : "Partial",
                            planId: plan,
                            planName: planName,
                            planCharges: planCharges,
                            chargingType: "monthly",
                            invoicePaymentMethod: object?.paymentMode,
                            printSetting: "Both",
                            isInvoice: true,

                            billingPeriod: {
                                from: "onActivation",
                                to: "onActivation",
                            },
                            userId: parseLoginRes._id,
                            isShipmentCostDiscounted: checkLabel,
                            shipmentCost: {
                                name: shipmentName,
                                amount: shipmentCost,
                            },
                        };
                        localStorage.setItem("datasendforinvoice", JSON.stringify(dataToSend));
                        Axios.post(`${BASE_URL}/api/web/invoices/prepaidgenerateInvoice`, dataToSend)
                            .then((response) => {
                                localStorage.setItem("paymentallinfo", JSON.stringify(response.data));
                                setActiveIndex(3);
                            })
                            .catch((err) => {});
                    }
                    toast.current.show({ severity: "success", summary: "Payment Processed", detail: response?.data?.data?.messages[0]?.description });
                    setIsLoading(false) 
               
                }
                else {
                    if(response?.data?.data?.responseCode === "2"){ 
                    
                        toast.current.show({ severity: "error", summary: "Payment Proceed", detail: response?.data?.data?.errors[0]?.errorText});
                        
                        }  
                    setIsLoading(false);
                }
            } catch (error) {
                toast.current.show({ severity: "error", summary: "Payment Proceed", detail: "Payment Proceeding Failed" });
                setIsLoading(false);
            }
            setIsLoading(false)

        },
    });

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <ToastContainer />
                <div className="col-12  ">
                    <div className="p-3">
                        <div className=" h-full ">
                            <div>
                                <table className="cp_table w-full">
                                    <tbody>

                                        <tr className="text-lg">
                                            <td>
                                                Total Amount: <span className="steric">*</span>
                                            </td>
                                            <td>
                                                {" "}
                                                <InputText type="text" id="amount" value={formik.values.amount} onChange={formik.handleChange} disabled />

                                            </td>
                                        </tr>
                                        <tr className="text-lg">
                                            <td>
                                                Amount <span className="steric">*</span>
                                            </td>
                                            <td>
                                                {" "}
                                                <InputText type="text" id="totalAmount" value={formik.values.totalAmount} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("totalAmount") }, "input_text")} />
                                                {getFormErrorMessage("totalAmount")}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="text-lg">
                                                Account Number <span className="steric">*</span>
                                            </td>
                                            <td>
                                                <InputText className={classNames({ " mr-3": true, "p-invalid": isFormFieldValid("AccountNumber") }, "input_text")} type="text" id="AccountNumber" value={formik.values.AccountNumber} maxLength={16} onChange={formik.handleChange} />
                                                {getFormErrorMessage("AccountNumber")}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-lg">
                                                Routing Number <span className="steric">*</span>
                                            </td>
                                            <td>
                                                <InputText className={classNames({ " mr-3": true, "p-invalid": isFormFieldValid("routingNumber") }, "input_text")} type="text" id="routingNumber" maxLength={9} value={formik.values.routingNumber} onChange={formik.handleChange} />
                                                {getFormErrorMessage("routingNumber")}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-lg">
                                                Name On Account <span className="steric">*</span>
                                            </td>
                                            <td>
                                                <InputText className={classNames({ " mr-3": true, "p-invalid": isFormFieldValid("NameOnAccount") }, "input_text")} type="text" id="NameOnAccount" value={formik.values.NameOnAccount} onChange={formik.handleChange} />

                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-lg">
                                                Account Type <span className="steric">*</span>
                                            </td>
                                            <td>
                                                <InputText className={classNames({ " mr-3": true, "p-invalid": isFormFieldValid("accountType") }, "input_text")} type="text" id="accountType" value={formik.values.accountType} onChange={formik.handleChange} />

                                            </td>
                                        </tr>
                                        <div className="mt-2">

                                        <Button label="Submit" type="submit"  disabled={isLoading} icon={isLoading === true ? "pi pi-spin pi-spinner " : ""} />
                                        </div>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </form>
        </>
    )
}
export default EcheckAuthPayment;