
import { Button } from "primereact/button";
import html2canvas from "html2canvas";
import "./css/customer_invoice_updated.css"
import { useRef, useEffect } from "react";
import jsPDF from "jspdf"; 
import moment from "moment"
import React from "react";



export default function CustomerInvoicePrepaidUpdated({ userDetails, invoiceDownload, invoiceData }) {

 
    const downloadButtonRef = useRef();

    useEffect(() => {
        if (invoiceData !== undefined && invoiceData !== null) {
            downloadButtonRef.current.click();
        }
    }, [invoiceDownload]);

    const downloadInvoice = () => {

        document.querySelector(".downloadtempupdated").style.width = "900px";
        html2canvas(document.querySelector(".downloadtempupdated"), { scale: 2 }).then((canvas) => {

            {/*   const pdf = new jsPDF('p', 'pt', 'a4');
            const createdAtDate = new Date(invoiceData?.createdAt);
            const formattedDate = createdAtDate.toLocaleDateString();
            pdf.addImage(canvas.toDataURL("image/png"), "PNG", 0, 0, pdf.internal.pageSize.width, pdf.internal.pageSize.height);
            pdf.save(`${formattedDate}-${userDetails?.lastName}-${userDetails?.accountId}.pdf`);
     */}
            const pdf = new jsPDF('p', 'pt', 'a4');
            const createdAtDate = new Date(invoiceData?.createdAt);
            const formattedDate = createdAtDate.toLocaleDateString();

            // A4 dimensions in points (1 inch = 72 points)
            const pdfWidth = pdf.internal.pageSize.getWidth();
            const pdfHeight = pdf.internal.pageSize.getHeight();

            // Canvas dimensions
            const canvasWidth = canvas.width;
            const canvasHeight = canvas.height;

            // Calculate the aspect ratio
            const aspectRatio = canvasWidth / canvasHeight;

            let imgWidth, imgHeight;

            // Fit the image within the PDF page size while preserving the aspect ratio
            if (canvasWidth > canvasHeight) {
                imgWidth = pdfWidth;
                imgHeight = pdfWidth / aspectRatio;
            } else {
                imgHeight = pdfHeight;
                imgWidth = pdfHeight * aspectRatio;
            }

            // Ensure the image does not exceed the PDF dimensions
            if (imgWidth > pdfWidth) {
                imgWidth = pdfWidth;
                imgHeight = imgWidth / aspectRatio;
            }
            if (imgHeight > pdfHeight) {
                imgHeight = pdfHeight;
                imgWidth = imgHeight * aspectRatio;
            }

            // Add the image starting from the top-left corner (0, 0)
            pdf.addImage(canvas.toDataURL("image/png"), "PNG", 0, 0, imgWidth, imgHeight);
            pdf.save(`${formattedDate}-${userDetails?.lastName}-${userDetails?.accountId}.pdf`);
        });
    };
    // Get role name  from login response
    const loginRes = localStorage.getItem("userData");
    const parseLoginRes = JSON.parse(loginRes);
    const companyName = parseLoginRes?.companyName;
    const companyNameToCapital = companyName.toUpperCase();
    {/*{companyNameToCapital.includes("ZISFONE") ? (
        <div className="ml-4">
            <img className="mb-0  pt-4" src="/zisfonelogo.png" height="50" width="170" />
            <h6 className="mt-0">170 Old Country Road, Suite 303, Mineola, NY 11501</h6>
        </div>
    ) : (
        <div className="ml-4">
            <img className="mb-0  pt-4" src="/companyLogo2.png" height="80" width="200" />
            <h6 className="mt-0">1755 Park Street, Suite 200, Naperville, IL 60563</h6>
        </div>
    )}*/}
    return (
        <div>
            <Button ref={downloadButtonRef} className="download-invoice" label="" onClick={downloadInvoice}></Button>

            <div className="flex flex-wrap justify-content-around p-4 downloadtempupdated">
                <div className="header-main flex flex-wrap flex-row justify-content-between align-items-center">
                    <div className="header-img">
                        {companyNameToCapital.includes("ZISFONE") ? (
                            <div className="ml-4">
                                <img src="/zisfonelogo.png" />
                            </div>
                        ) : companyNameToCapital.includes("IJ") ? (
                            <div className="ml-4">
                                <img src="/companyLogo2.png" />
                            </div>
                        ):companyNameToCapital?.includes("MAGNET") ?  
                        <div className="ml-4">
                        <img src="/magnetlogo.png" />
                    </div>
                          
                          :""}
                    </div>
                    <div className="remittence-section-updated">
                        <p>REMITTENCE
                            <br />SECTION</p>
                    </div>
                </div>
                <div className="main-ivoice-info flex flex flex-wrap flex-row justify-content-between ">
                  <div className="main-company-custname  flex flex-wrap flex-row jusitfy-content-between "> 
                     <div className="flex flex-wrap flex-row w-full  justify-content-center">
                <div className="company-name-address">
                        <div className="flex flex-wrap flex-row justify-content-between">
                            <div className="w-full flex flex-wrap flex-row justify-content-between">
                                
                                <p className="company-name-field-width">
                                    {companyNameToCapital}</p>
                            </div>
                            <div className="mt-2 company-adress w-full flex flex-wrap flex-row justify-content-between">
                               
                                <p className="company-address-field-width">
                                    {
                                        companyNameToCapital === "ZISFONE LLC" ? <p>170 OLD COUNTRY RD, STE 303<br/>Mineola, NY 11501</p> : companyNameToCapital === "IJ WIRELESS" ? <p>1755 PARK ST, Ste 200,<br/>NAPERVILLE, IL 60563</p>: companyNameToCapital === "MAGNET WIRELESS, INC" ? <p>156 E 3rd St, Mount Vernon, NY 10550</p>:""
                                    }</p>
                            </div>
                        </div>
                    </div>   
                    <div className="flex  w-full flex-wrap flex-row justify-content-center  ">
                    <div className="cust-name-address flex flex-wrap flex-row justify-content-center">
                        <div className="cust-name-address-container">
                            <div className="w-full flex  flex-wrap flex-row justify-content-between">
                                <div className="w-full flex flex-wrap flex-row justify-content-between">
                                
                                    <p className="name-field-width">
                                        {userDetails?.firstName} {userDetails?.lastName}</p>
                                </div>
                                <div className="mt-1 company-adress w-full flex flex-wrap flex-row justify-content-between">
                                 
                                    <p className="address-field-width">
                                    {userDetails?.isSameServiceAddress ? (userDetails?.address1 +`${userDetails?.address2 ? userDetails?.address2 :""}`)?.toUpperCase() :userDetails?.isNotSameServiceAddress ? (userDetails?.mailingAddress1 + `${userDetails?.mailingAddress2 ? userDetails?.mailingAddress2 :""}`)?.toUpperCase() : userDetails?.isPoBoxAddress ? (userDetails?.PoBoxAddress)?.toUpperCase() : ""}<br/> 
                                    {userDetails?.isSameServiceAddress ? (userDetails?.city)?.toUpperCase()  :userDetails?.isNotSameServiceAddress ? (userDetails?.mailingCity)?.toUpperCase()   : userDetails?.isPoBoxAddress ? (userDetails?.poBoxCity)?.toUpperCase()  :""},&nbsp;{  userDetails?.isSameServiceAddress ? (userDetails?.state)?.toUpperCase()  :userDetails?.isNotSameServiceAddress ? (userDetails?.mailingState)?.toUpperCase()   : userDetails?.isPoBoxAddress ? (userDetails?.poBoxState)?.toUpperCase()  :""}&nbsp;{ 
                                                    userDetails?.isSameServiceAddress ? userDetails?.zip :userDetails?.isNotSameServiceAddress ? userDetails?.mailingZip  : userDetails?.isPoBoxAddress ? userDetails?.poBoxZip :""
                                                    
                                                      }
                                                            
                                    </p>
                                </div>
                            </div>



                        </div>
                    </div>
                   
                </div>      
                 </div>
                 </div>
                       <div className="main-remittence-details">
                        <div className="flex w-full flex-wrap flex-row justify-content-between">
                            <p>ACCOUNT NO:</p>
                            <p>{userDetails?.accountId}</p>
                        </div>
                        <div className="flex flex-wrap w-full  flex-row justify-content-between">
                            <p>INVOICE NO:</p>
                            <p>{invoiceData?.invoiceNo}</p>
                        </div>
                        <div className="flex flex-wrap w-full flex-row justify-content-between">
                            <p>DUE DATE:</p>
                            <p>{  invoiceData?.invoiceDueDate !== "-" ? moment(invoiceData?.invoiceDueDate, 'M-D-YYYY').format('YYYY-MM-DD'):"-"}</p>
                        </div>
                        <div className="flex flex-wrap w-full  flex-row justify-content-between">
                            <p>DUE AMOUNT:</p>
                            <p>${invoiceData?.dueAmount !== "" && invoiceData?.dueAmount !== undefined && invoiceData?.dueAmount !== "NaN" ? parseFloat(invoiceData?.dueAmount).toFixed(2):"0.00"}</p>
                        </div>
                        <div className="flex flex-wrap w-full flex-row justify-content-between">
                            <p>AMOUNT AFTER DUE DATE:</p>

                            <p>${invoiceData?.amountAfterDueDate !== undefined && invoiceData?.amountAfterDueDate !== "" && invoiceData?.amountAfterDueDate !== "NaN"  ? parseFloat(invoiceData?.amountAfterDueDate ).toFixed(2):"0.00"}</p>
                        </div>
                        <div className="make_check_payable">
                            <p className="text-center">Please Make Checks Payable To: {companyNameToCapital}</p>
                        </div>
                    </div>
                </div>
               
                <div className="w-full return-payment-portion">
                    <p className="">PLEASE RETURN THIS PORTION WITH YOUR PAYMENT</p>
                    <hr className="dashed" />
                </div>
                <div className="invoice-service-box flex flex-wrap flex-row flex-row justify-content-center">
                    <div className="invoice-service-container">
                        <div className="invoice-service-header flex flex-wrap flex-row justify-content-between ">
                            <p className="invoiceinfo">Service Information</p>
                            <p className="serviceinfo">Invoice Information</p>
                            <div className="invoiceservice-all-details">
                                <div className="w-full flex for-border-bottom flex-wrap flex-row justify-content-between">
                                    <p className="invoice-item"> ONE TIME CHARGE</p>
                                    <p className="invoice-val"> &nbsp;&nbsp;{invoiceData?.invoiceOneTimeCharges ? parseFloat(invoiceData?.invoiceOneTimeCharges).toFixed(2)  :"0.00" }</p>
                                </div>
                             
                                <div className="w-full flex  for-border-bottom flex-wrap flex-row justify-content-between">
                                    <p className="invoice-item"> PLAN CHARGES</p>
                                    <p className="invoice-val">  &nbsp;&nbsp;{invoiceData?.planCharges ? parseFloat(invoiceData?.planCharges)?.toFixed(2) : "0.00"}</p>
                                </div>

                                <div className="w-full flex  for-border-bottom flex-wrap flex-row justify-content-between">
                                    <p className="invoice-item">ADDITIONAL CHARGES</p>
                                    <p className="invoice-val"> &nbsp;&nbsp;{invoiceData?.additionalCharges 
    ? parseFloat(invoiceData.additionalCharges.reduce((accumulator, current) => {
        return accumulator + parseFloat(current.amount);
    }, 0)).toFixed(2) 
    : "0.00"}
</p>
                                </div>   
                                <div className="w-full flex for-border-bottom flex-wrap flex-row justify-content-between">
                                    <p className="invoice-item">TAXES</p>
                                    <p className="invoice-val"> &nbsp;&nbsp;{invoiceData?.tax !== undefined && invoiceData?.tax !== "" && invoiceData?.tax !== "NaN" ? invoiceData?.tax?.toFixed(2) : "0.00"}</p>
                                </div>
                                <div className="w-full flex  for-border-bottom flex-wrap flex-row justify-content-between">
                                    <p className="invoice-item">DISCOUNTS</p>
                                    <p className="invoice-val">-{invoiceData?.discount ? 
    parseFloat(invoiceData.discount.reduce((accumulator, current) => {
        return accumulator + parseFloat(current.amount);
    }, 0)).toFixed(2) : 
    "0.00"}</p>
                                </div>   
                                <div className="w-full flex for-border-bottom flex-wrap flex-row justify-content-between">
                                    <p className="invoice-item">SHIPPING CHARGES</p>
                                    <p className="invoice-val"> &nbsp;&nbsp;{invoiceData?.shipmentCost?.amount  ? parseFloat(invoiceData?.shipmentCost?.amount).toFixed(2) : 0.00}</p>
                                </div>

                            </div>
                            <div className="invoiceinfo-all-details">
                                <div className="w-full flex for-border-bottom flex-wrap flex-row justify-content-between">
                                    <p className="invoice-item"> ACCOUNT NO</p>
                                    <p className="invoice-val">{userDetails?.accountId}</p>
                                </div>
                                <div className="w-full flex  for-border-bottom flex-wrap flex-row justify-content-between">
                                    <p className="invoice-item" > INVOICE NO</p>
                                    <p className="invoice-val" >{invoiceData?.invoiceNo}</p>
                                </div>
                                <div className="w-full flex  for-border-bottom flex-wrap flex-row justify-content-between">
                                    <p className="invoice-item"> CUSTOMER NAME</p>
                                    <p className="invoice-val">{userDetails?.firstName} {userDetails?.middleName ? userDetails?.middleName:userDetails?.middleName} {userDetails?.lastName}</p>
                                </div>
                                <div className="w-full flex  for-border-bottom flex-wrap flex-row justify-content-between">
                                    <p className="invoice-item"> DUE DATE</p>
                                    <p className="invoice-val">{invoiceData?.invoiceDueDate !== "-" ? moment(invoiceData?.invoiceDueDate, 'M-D-YYYY').format('YYYY-MM-DD'):"-"}</p>
                                </div>
                                <div className="w-full flex  for-border-bottom flex-wrap flex-row justify-content-between">
                                    <p className="invoice-item"> DUE AMOUNT</p>
                                    <p className="invoice-val">${invoiceData?.dueAmount !== "NaN" && invoiceData?.dueAmount !== "" && invoiceData?.dueAmount !== undefined ? parseFloat(invoiceData?.dueAmount).toFixed(2):"0.00"}</p>
                                </div>
                                <div className="w-full flex for-border-bottom flex-wrap flex-row justify-content-between">
                                    <p className="invoice-item">AMOUNT AFTER DUE DATE</p>
                                    <p className="invoice-val">${invoiceData?.amountAfterDueDate!==undefined && invoiceData?.amountAfterDueDate !== "" && invoiceData?.amountAfterDueDate !== "NaN" ? parseFloat(invoiceData?.amountAfterDueDate).toFixed(2):"0.00"}</p>
                                </div>
                            </div>
                           
                        </div>
                    </div>  
                    
                    {invoiceData?.invoiceVoid ? <img className="void-img" src="/void.png" height="80" width="200" /> : ""}
                </div>      
                 <div className="w-full flex flex-wrap flex-row justify-content-left"> 
       <div className="current-balance flex flex-wrap flex-row justify-content-between"> 
        <p>Current Balance</p> 
         <p className="currentbalancevalue">${invoiceData?.totalAmount !== "NaN" && invoiceData?.totalAmount !== "" && invoiceData?.totalAmount !== undefined ? parseFloat(invoiceData?.totalAmount).toFixed(2):"0.00"}</p>
       </div>
                 </div> 
                  <div className="w-full flex flex-wrap flex-row justify-content-left">
                 <div className="payment-calculation-info   flex flex-wrap flex-row justify-content-left">  
                      <div className="payment-calculation-main">
             <h1>Payment Info:</h1>
             <div className="mt-2 current-bill w-full flex flex-wrap flex-row justify-content-between">
                                    <p>CURRENT BILL</p>
                                    <p className="currentbill-val">
                                        {invoiceData?.currentBalance!== undefined && invoiceData?.currentBalance !== "NaN" && invoiceData?.currentBalance !== "" ? parseFloat(invoiceData?.currentBalance).toFixed(2) : "0.00"}</p>
                                </div>
                                <div className="previous-bill w-full flex flex-wrap flex-row justify-content-between">
                                    <p>PREVIOUS BALANCE</p>
                                    <p className="prevbill-val">
                                        {invoiceData?.previousBalance ? parseFloat(invoiceData?.previousBalance).toFixed(2) : "0.00"}</p>
                                
                 </div> 
                 
                 <hr className="hr-width"/> 
                  <div className="flex flex-wrap flex-wrap justify-content-end w-full"> 
                  <p className="totalbill-val">
                  ${invoiceData?.previousBalance !== "NaN" && invoiceData?.previousBalance !== undefined && invoiceData ?.currentBalance !== "" && invoiceData ?.currentBalance !== "NaN" && invoiceData ?.currentBalance !== undefined  ? (parseFloat(invoiceData?.previousBalance)+parseFloat(invoiceData?.currentBalance)).toFixed(2):(invoiceData?.previousBalance === "NaN" || invoiceData?.previousBalance === undefined || invoiceData?.previousBalance === "") && (invoiceData ?.currentBalance !== "" && invoiceData ?.currentBalance !== "NaN" && invoiceData ?.currentBalance !== undefined) ?  parseFloat(invoiceData?.currentBalance).toFixed(2):(invoiceData?.previousBalance === "NaN" && invoiceData?.previousBalance === undefined && invoiceData?.previousBalance !== "") && (invoiceData ?.currentBalance === "" || invoiceData ?.currentBalance === "NaN" || invoiceData ?.currentBalance === undefined) ?  parseFloat(invoiceData?.previousBalance).toFixed(2):"0.00"}</p>
                  </div>        
                  <div className="amound-paid-bill w-full flex flex-wrap flex-row justify-content-between">
                                    <p>Amount Paid</p>
                                    <p className="prevbill-val">
                                        ${invoiceData?.amountPaid  === "" || invoiceData?.amountPaid === "NaN" || invoiceData?.amountPaid === undefined ? "0.00":parseFloat(invoiceData?.amountPaid)?.toFixed(2)}</p>
                                
                 </div>   
                 <div  style={{paddingLeft:"2px",paddingRight:"2px",borderRadius:"1px",backgroundColor:"rgba(103,102,103,255)",height:"22px",color:"white"}} className="amound-paid-bill w-full flex flex-wrap flex-row justify-content-between">
                 <p>TOTAL DUE</p> 
                 <p>${invoiceData?.dueAmount !== "NaN" && invoiceData?.dueAmount !== undefined && invoiceData?.dueAmount !== "" ? parseFloat(invoiceData?.dueAmount).toFixed(2):"0.00" }</p>     
                 </div> 
                 
                 </div>    
                
                  
                    </div>  
                    <div className="query-info"> 
                    <p className="text-center">FOR ANY QUERIES ABOUT YOUR BILL OR TO PAY YOUR BILL </p>  
                    <p className="text-center">PLEASE CALL OUR CUSTOMER SERVICE TEAM AT {companyNameToCapital === "ZISFONE LLC" ? "(833) 666-2835" : companyNameToCapital === "IJ WIRELESS" ? "(833) 675-5227" : companyNameToCapital === "MAGNET WIRELESS, INC" ? "(877) 363-1614":""} From 8 AM TO 8 PM EST,  </p>
                     <p className="text-center">MONDAY THROUGH FRIDAY , AND FROM 8 AM TO 5 PM EST ON SATURDAY</p>                  
                   </div>    
                   <div className="bottom-border-line"> 
          <hr></hr>
                   </div>    
                    <div className="w-full">
                    <div className="footer-contact flex flex-wrap flex-row  justify-content-center"> 
                      <div className="flex phone-flex flex-wrap flex-row justify-content-left "> 
                  <i className="pi phone-icon-color pi-phone"/> 
                   <p >{companyNameToCapital === "ZISFONE LLC" ? "(833) 666-2835" : companyNameToCapital === "IJ WIRELESS" ? "(833) 675-5227": companyNameToCapital === "MAGNET WIRELESS, INC" ? "(877) 363-1614":""}</p>
                      </div>  
                      <div className="flex phone-web flex-wrap flex-row justify-content-left "> 
                      <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px"><path d="M480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-155.5t86-127Q252-817 325-848.5T480-880q83 0 155.5 31.5t127 86q54.5 54.5 86 127T880-480q0 82-31.5 155t-86 127.5q-54.5 54.5-127 86T480-80Zm0-82q26-36 45-75t31-83H404q12 44 31 83t45 75Zm-104-16q-18-33-31.5-68.5T322-320H204q29 50 72.5 87t99.5 55Zm208 0q56-18 99.5-55t72.5-87H638q-9 38-22.5 73.5T584-178ZM170-400h136q-3-20-4.5-39.5T300-480q0-21 1.5-40.5T306-560H170q-5 20-7.5 39.5T160-480q0 21 2.5 40.5T170-400Zm216 0h188q3-20 4.5-39.5T580-480q0-21-1.5-40.5T574-560H386q-3 20-4.5 39.5T380-480q0 21 1.5 40.5T386-400Zm268 0h136q5-20 7.5-39.5T800-480q0-21-2.5-40.5T790-560H654q3 20 4.5 39.5T660-480q0 21-1.5 40.5T654-400Zm-16-240h118q-29-50-72.5-87T584-782q18 33 31.5 68.5T638-640Zm-234 0h152q-12-44-31-83t-45-75q-26 36-45 75t-31 83Zm-200 0h118q9-38 22.5-73.5T376-782q-56 18-99.5 55T204-640Z"/></svg>
                   <p className="">{companyNameToCapital === "ZISFONE LLC"  ? "WWWW.ZISFONE.COM":companyNameToCapital .includes("IJ") ? "HTTPS://IJWIRELESS.US":companyNameToCapital.includes("MAGNET") ? "https://magnetwireless.co":""}</p>
                      </div>
                    </div> 
                     </div>
                     </div>
                 
            </div>

        </div>
    );
}

