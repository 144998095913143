import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React, { useState } from "react"; 
import PaymentStripModule from "./stripe_payment";
export default function StripePaymentDialog({cpData,setRefresh,setPayinvoicesVisible,selectedInvoices,totalAmount}){  
    const [amountToPaid,setAmountToPaid]=useState() 
    const [showStripe,setShowStripe]=useState(false)
    return( 
        <div> 
             {    
          !showStripe ?   <>
        <InputText  className="stripepaymentamount" keyfilter="num" value={amountToPaid} onChange={(e)=>{ 
             setAmountToPaid(e.target.value)
        }} /> 
          <Button className="stripepaymentbutton btn" label="Submit" onClick={()=>{ 
           setShowStripe(true)
          }}/>    
           </>:
         
           <PaymentStripModule setRefresh={setRefresh} setShowStripe={setShowStripe} cpData={cpData} selectedInvoices={selectedInvoices} amount={amountToPaid}/> 
          }
        </div>
    )
}