import React, { useRef, useState } from "react";
import { Toast } from "primereact/toast";
import "./style/stripe_payment_form.css";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import Axios from "axios";
import { InputSwitch } from "primereact/inputswitch";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import PaymentStripModuleAlternateCard from "../../Alternate card Dialogs/stripe_payment";
//import AlternateCardPaymentStripModule from "../../AlternateCardAutoPay/stripe_payment_dialog/stripe_payment";
const BASE_URL = process.env.REACT_APP_BASE_URL;
export default function PaymentStripeForm({_id, plan, clientSecret,setShowPreview, paid, object, setActiveIndex, setPaymentDialogVisibility }) {
    const submitbuttonref = useRef(null); 
    const [cardNumber,setCardNumber]=useState("") 
    const [cvcNumber,setCvcNumber]=useState("") 
    const [cardMonth,setCardMonth]=("")  
    const [cardYear,setCardYear]=useState("")
    const [autoPayDate,setAutoPayDate]=useState("")  
    const [alternateCardToken,setAlternateCardToken]=useState("") 
    const [alternateCardDetailVisibility, setAlternateCardDetailVisibility] = useState(false);
    const [autoPay, setAutoPay] = useState(true);
    const stripe = useStripe();
    const [disableSubmit, setDisableSubmit] = useState(false);
    const toast = useRef(null);
    const elements = useElements();
    const handleSubmit = async (event) => { 
        submitbuttonref.current.style.opacity = "0.5";
        setDisableSubmit(true);
        event.preventDefault();
        if (!stripe || !elements) {
            return;
        }      
        const { paymentIntent, error } = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: elements.getElement(CardElement),
            },
        });    
          
      

        if (error) {     
            Axios.post(`${process.env.REACT_APP_BASE_URL}/api/web/billing/updatePaymentLog`,{  
                "customerId":_id,
       "paymentMethod":"Credit Card",
       "stripeId":paymentIntent?.id,
       action:"failure",
       reason:"failure",
       transactionStatus:paymentIntent?.status,
       paymentStatus:"failure",
       "amount":paid
             }).then((res)=>{ 
                 toast.current.show({ severity: "success", summary: "Payment Logs", detail: "Payment Logs Successfully Saved" });
                 
             }).catch(err=>{ 
                 toast.current.show({ severity: "error", summary: "Payment Logs", detail: "Payment Logs Saving Failed" });
               
             }) 
            localStorage.setItem("paymentstatus", "pending");

            toast.current.show({ severity: "error", summary: "Payment Processing Error", detail: "An error occurred while processing the payment" });
     
             
            setTimeout(()=>{ 
                   setPaymentDialogVisibility(false)
            },1000)
        } else {     
            Axios.post(`${process.env.REACT_APP_BASE_URL}/api/web/billing/updatePaymentLog`,{  
                "customerId":_id,
       "paymentMethod":"Credit Card",
       "stripeId":paymentIntent?.id,
       action:"success",
       reason:"success",
       transactionStatus:paymentIntent?.status,
       paymentStatus:"success",
       "amount":paid
             }).then((res)=>{ 
                 toast.current.show({ severity: "success", summary: "Payment Logs", detail: "Payment Logs Successfully Saved" });
                 
             }).catch(err=>{ 
                 toast.current.show({ severity: "error", summary: "Payment Logs", detail: "Payment Logs Saving Failed" });
               
             }) 
             if(alternateCardToken === ""){      
            stripe.createToken(elements.getElement(CardElement)).then(function(result) {       
                 const token=result?.token?.id;      
                   let customerData=JSON.parse(localStorage.getItem("initialInformation"))?.data
                 const datatosendincreateAccount={     
                    "name": `${customerData?.firstName} ${customerData?.lastName}`,
     "email": `${customerData?.email}`,
     "phone": `+1${customerData?.contact}`,
    "description": "New customer account"
                 }
                 Axios.post(`${BASE_URL}/api/bannerRouter/create_account`, datatosendincreateAccount)
                 .then((customercreation) => {
            let customerId=customercreation?.data?.data?.id       
            localStorage.setItem("paymentstatus", "paid");

            localStorage.setItem("stripeId", paymentIntent.id);

            //setActiveIndex(3);
            let paymentproceed = localStorage.getItem("paymentstatus");

            if (paymentproceed === "paid") {
                let additionalFeature = [];
                let discounts = [];

                let devicepricing = JSON.parse(localStorage.getItem("devicepricing"));
                let simpricing = JSON.parse(localStorage.getItem("simpricing"));
                let dueDate = "";
                let applyLateFee = "";
                let oneTimeCharge = "";
                let planName = "";
                let planId = "";
                let planCharges = "";
                if (object?.billId === simpricing?._id) {
                    dueDate = simpricing?.dueDate;
                    oneTimeCharge = simpricing?.oneTimeCharge;
                    applyLateFee = simpricing?.applyLateFee;
                    let simselecteddiscounts = JSON.parse(localStorage.getItem("simdiscountobjectarray"));

                    for (let i = 0; i < simpricing?.selectdiscount?.length; i++) {
                        for (let k = 0; k < simselecteddiscounts.length; k++) {
                            if (simselecteddiscounts[k] === simpricing?.selectdiscount[i]?._id) {
                                let obj = {
                                    name: simpricing?.selectdiscount[i]?.discountname,
                                    amount: simpricing?.selectdiscount[i]?.amount,
                                };
                                discounts.push(obj);
                            }
                        }
                    }
                    let plandata = JSON.parse(localStorage.getItem("planprices"));
                    for (let i = 0; i < plandata?.length; i++) {
                        if (object.plan === plandata[i]?._id) {
                            planName = plandata[i]?.name;
                            planCharges = plandata[i]?.price;

                            planId = plandata[i]?._id;
                        }
                    }

                    let simadditional = JSON.parse(localStorage.getItem("simadditionalfeaturearray"));
                    for (let k = 0; k < simadditional?.length; k++) {
                        for (let i = 0; i < simpricing?.additionalFeature?.length; i++) {
                            if (simpricing?.additionalFeature[i]?._id === simadditional[k]) {
                                let obj = {
                                    name: simpricing?.additionalFeature[i]?.featureName,
                                    amount: simpricing?.additionalFeature[i]?.featureAmount,
                                };
                                additionalFeature.push(obj);
                            }
                        }
                    }
                } else {
                    let plandata = JSON.parse(localStorage.getItem("planprices"));
                    dueDate = devicepricing?.dueDate;
                    applyLateFee = devicepricing?.applyLateFee;
                    oneTimeCharge = devicepricing?.oneTimeCharge;
                    let deviceselecteddiscounts = JSON.parse(localStorage.getItem("devicediscountobjectarray"));

                    for (let i = 0; i < devicepricing?.selectdiscount?.length; i++) {
                        for (let k = 0; k < deviceselecteddiscounts.length; k++) {
                            if (deviceselecteddiscounts[k] === devicepricing?.selectdiscount[i]?._id) {
                                let obj = {
                                    name: devicepricing?.selectdiscount[i]?.discountname,
                                    amount: devicepricing?.selectdiscount[i]?.amount,
                                };
                                discounts.push(obj);
                            }
                        }
                    }
                    for (let i = 0; i < plandata?.length; i++) {
                        if (object.plan === plandata[i]?._id) {
                            planName = plandata[i]?.name;
                            planCharges = plandata[i]?.price;

                            planId = plandata[i]?._id;
                        }
                    }
                    let deviceadditional = JSON.parse(localStorage.getItem("deviceadditionalfeaturearray"));
                    for (let k = 0; k < deviceadditional?.length; k++) {
                        for (let i = 0; i < devicepricing?.additionalFeature?.length; i++) {
                            if (deviceadditional[k] === devicepricing?.additionalFeature[i]?._id) {
                                let obj = {
                                    name: devicepricing?.additionalFeature[i]?.featureName,
                                    amount: devicepricing?.additionalFeature[i]?.featureAmount,
                                };
                                additionalFeature.push(obj);
                            }
                        }
                    }
                }
                let plan = object?.plan;
                // let dateincasepart
                //object.totalAmount === paid ? dueDate:"Partial"  
                const loginRes = localStorage.getItem("userData");
                const parseLoginRes = JSON.parse(loginRes);
                let dataToSend = {
                    paymentId: paymentIntent.id,
                    isAutopay: autoPay,

                    customerId: object.customerid,
                    invoiceType: "Sign Up",
                    totalAmount: object.totalamount,
                    additionalCharges: additionalFeature,
                    discount: discounts,   
                    amountPaid: paid,
                    selectProduct: object?.billId,
                    invoiceDueDate: dueDate,
                    lateFee: applyLateFee,
                    invoiceOneTimeCharges: oneTimeCharge,
                    invoiceStatus:object.totalamount === paid? "Paid" : "Partial",
                    planId: plan,
                    planName: planName,
                    planCharges: planCharges,
                    chargingType: "monthly",
                    invoicePaymentMethod: "Credit/Debit Card",
                    printSetting: "Both",
                    isInvoice: true,     

                    billingPeriod: {
                        from: "onActivation",
                        to: "onActivation",
                    }, 
                    userId:parseLoginRes?._id
                };
                if (autoPay) {
                 
                    dataToSend.paymentMethodId=token 
                    dataToSend.stripeCustomerId=customerId
                
                }
                localStorage.setItem("datasendforinvoice", JSON.stringify(dataToSend));
                Axios.post(`${BASE_URL}/api/web/invoices/prepaidgenerateInvoice`, dataToSend)
                    .then((response) => {    
                        Axios.post(`${process.env.REACT_APP_BASE_URL}/api/web/billing/updatePaymentLog`,{  
                            "customerId":_id,
                   "paymentMethod":"Credit Card",  
                   "invoiceNo":response?.data?.data?.invoice,
                   "stripeId":paymentIntent?.id,
                   action:"success",
                   reason:"success",
                   transactionStatus:paymentIntent?.status,   
                   userId:parseLoginRes?._id,
                   paymentStatus:"success",
                   "amount":paid
                         }).then((res)=>{ 
                             toast.current.show({ severity: "success", summary: "Payment Logs", detail: "Payment Logs Successfully Saved" });
                             
                         }).catch(err=>{ 
                             toast.current.show({ severity: "error", summary: "Payment Logs", detail: "Payment Logs Saving Failed" });
                           
                         })
                        localStorage.setItem("paymentallinfoself", JSON.stringify(response.data));
                   
                       
                        
                            setShowPreview(true)
                    })
                    .catch((err) => {});
            }
            toast.current.show({ severity: "success", summary: "Payment Processed", detail: "Payment has been successfully processed" });
       }).catch(err=>{ 
                    
                 }) }).catch(err=>{ 
                
        })   
    } 
    else{ 
        let customerData=JSON.parse(localStorage.getItem("prepaidbasicData"))?.data
        const datatosendincreateAccount={     
           "name": `${customerData?.firstName} ${customerData?.lastName}`,
"email": `${customerData?.email}`,
"phone": `+1${customerData?.contact}`,
"description": "New customer account"
        }
        Axios.post(`${BASE_URL}/api/bannerRouter/create_account`, datatosendincreateAccount)
        .then((customercreation) => {
       let customerId=customercreation?.data?.data?.id
   localStorage.setItem("paymentstatus", "paid");

   localStorage.setItem("stripeId", paymentIntent.id);

   //setActiveIndex(3);
   let paymentproceed = localStorage.getItem("paymentstatus");

   if (paymentproceed === "paid") {
       let additionalFeature = [];
       let discounts = [];

       let devicepricing = JSON.parse(localStorage.getItem("devicepricing"));
       let simpricing = JSON.parse(localStorage.getItem("simpricing"));
       let dueDate = "";
       let applyLateFee = "";
       let oneTimeCharge = "";
       let planName = "";
       let planId = "";
       let planCharges = "";
       if (object?.billId === simpricing?._id) {
           dueDate = simpricing?.dueDate;
           oneTimeCharge = simpricing?.oneTimeCharge;
           applyLateFee = simpricing?.applyLateFee;
           let simselecteddiscounts = JSON.parse(localStorage.getItem("simdiscountobjectarray"));

           for (let i = 0; i < simpricing?.selectdiscount?.length; i++) {
               for (let k = 0; k < simselecteddiscounts.length; k++) {
                   if (simselecteddiscounts[k] === simpricing?.selectdiscount[i]?._id) {
                       let obj = {
                           name: simpricing?.selectdiscount[i]?.discountname,
                           amount: simpricing?.selectdiscount[i]?.amount,
                       };
                       discounts.push(obj);
                   }
               }
           }
           let plandata = JSON.parse(localStorage.getItem("planprices"));
           for (let i = 0; i < plandata?.length; i++) {
               if (object.plan === plandata[i]?._id) {
                   planName = plandata[i]?.name;
                   planCharges = plandata[i]?.price;

                   planId = plandata[i]?._id;
               }
           }

           let simadditional = JSON.parse(localStorage.getItem("simadditionalfeaturearray"));
           for (let k = 0; k < simadditional?.length; k++) {
               for (let i = 0; i < simpricing?.additionalFeature?.length; i++) {
                   if (simpricing?.additionalFeature[i]?._id === simadditional[k]) {
                       let obj = {
                           name: simpricing?.additionalFeature[i]?.featureName,
                           amount: simpricing?.additionalFeature[i]?.featureAmount,
                       };
                       additionalFeature.push(obj);
                   }
               }
           }
       } else {
           let plandata = JSON.parse(localStorage.getItem("planprices"));
           dueDate = devicepricing?.dueDate;
           applyLateFee = devicepricing?.applyLateFee;
           oneTimeCharge = devicepricing?.oneTimeCharge;
           let deviceselecteddiscounts = JSON.parse(localStorage.getItem("devicediscountobjectarray"));

           for (let i = 0; i < devicepricing?.selectdiscount?.length; i++) {
               for (let k = 0; k < deviceselecteddiscounts.length; k++) {
                   if (deviceselecteddiscounts[k] === devicepricing?.selectdiscount[i]?._id) {
                       let obj = {
                           name: devicepricing?.selectdiscount[i]?.discountname,
                           amount: devicepricing?.selectdiscount[i]?.amount,
                       };
                       discounts.push(obj);
                   }
               }
           }
           for (let i = 0; i < plandata?.length; i++) {
               if (object.plan === plandata[i]?._id) {
                   planName = plandata[i]?.name;
                   planCharges = plandata[i]?.price;

                   planId = plandata[i]?._id;
               }
           }
           let deviceadditional = JSON.parse(localStorage.getItem("deviceadditionalfeaturearray"));
           for (let k = 0; k < deviceadditional?.length; k++) {
               for (let i = 0; i < devicepricing?.additionalFeature?.length; i++) {
                   if (deviceadditional[k] === devicepricing?.additionalFeature[i]?._id) {
                       let obj = {
                           name: devicepricing?.additionalFeature[i]?.featureName,
                           amount: devicepricing?.additionalFeature[i]?.featureAmount,
                       };
                       additionalFeature.push(obj);
                   }
               }
           }
       }
       let plan = object?.plan;
       // let dateincasepart
       //object.totalAmount === paid ? dueDate:"Partial" 
       const loginRes = localStorage.getItem("userData");
       const parseLoginRes = JSON.parse(loginRes);
       let dataToSend = {
           paymentId: paymentIntent.id,
           isAutopay: autoPay,

           customerId: object.customerid,
           invoiceType: "Sign Up",
           totalAmount: object.totalamount,
           additionalCharges: additionalFeature,
           discount: discounts,   
           amountPaid: paid,
           selectProduct: object?.billId,
           invoiceDueDate: dueDate,
           lateFee: applyLateFee,
           invoiceOneTimeCharges: oneTimeCharge,
           invoiceStatus: object.totalamount === paid ? "Paid" : "Partial",
           planId: plan,
           planName: planName,
           planCharges: planCharges,
           chargingType: "monthly",
           invoicePaymentMethod: "Credit/Debit Card",
           printSetting: "Both",
           isInvoice: true,     
           userId:parseLoginRes?._id,
           billingPeriod: {
               from: "onActivation",
               to: "onActivation",
           },
       };
       if (autoPay) {
           dataToSend.paymentMethodId=alternateCardToken
           dataToSend.stripeCustomerId=customerId
        
       }
       localStorage.setItem("datasendforinvoice", JSON.stringify(dataToSend));
       Axios.post(`${BASE_URL}/api/web/invoices/prepaidgenerateInvoice`, dataToSend)
           .then((response) => {   
            Axios.post(`${process.env.REACT_APP_BASE_URL}/api/web/billing/updatePaymentLog`,{  
                "customerId":_id,
       "paymentMethod":"Credit Card",  
       "invoiceNo":response?.data?.data?.invoice,
       "stripeId":paymentIntent?.id,
       action:"success",
       reason:"success",
       transactionStatus:paymentIntent?.status,   
       userId:parseLoginRes?._id,
       paymentStatus:"success",
       "amount":paid
             }).then((res)=>{ 
                 toast.current.show({ severity: "success", summary: "Payment Logs", detail: "Payment Logs Successfully Saved" });
                 
             }).catch(err=>{ 
                 toast.current.show({ severity: "error", summary: "Payment Logs", detail: "Payment Logs Saving Failed" });
               
             })
               localStorage.setItem("paymentallinfoself", JSON.stringify(response.data));
            
          

              
                   setShowPreview(true)
           })
           .catch((err) => {});
   }
   toast.current.show({ severity: "success", summary: "Payment Processed", detail: "Payment has been successfully processed" });
}).catch(err=>{ 
           
        }) 
    }
        }
    };
    const cardElementOptions = {
        hidePostalCode: false,
        style: {
            base: {
                fontSize: "16px",
                color: "#32325d",
                fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                "::placeholder": {
                    color: "#aab7c4",
                },
            },
            invalid: {
                color: "#fa755a",
                iconColor: "#fa755a",
            },
        },
    };
    return (
        <>
            <Toast ref={toast} />
            <div className="flex w-full flex-wrap flex-row mb-4 p-2 justify-content-left ">
                <div>
                    <div className=" p-2 flex flex-wrap flex-row justify-left">
                        <InputSwitch
                            checked={autoPay}
                            onChange={(e) => {
                                setAutoPay((prev) => !prev);
                            }}
                        />
                        <p className="ml-2"> Auto Pay</p>
                    </div>
                </div>
                {autoPay ? (
                    <Button
                        label="Auto Pay Card Details"
                        onClick={() => {
                            setAlternateCardDetailVisibility(true);
                        }}
                        className="ml-4"
                    />
                ) : undefined}
            </div>

            <form onSubmit={handleSubmit}>
                <CardElement options={cardElementOptions} />
                <button style={{ color: "white" }} ref={submitbuttonref} disabled={disableSubmit} className="submit-button">
                    Submit
                </button>
            </form>
            <Dialog
                header="Alternate Card Details"
                visible={alternateCardDetailVisibility}
                style={{ width: "50vw" }}
                onHide={() => {
                    setAlternateCardDetailVisibility(false);
                }}
            >
              {/*  <AlternateCardPaymentStripModule setAutoPayDate={setAutoPayDate} cardNumber={cardNumber} cardYear={cardYear} cardMonth={cardMonth} cvcNumber={cvcNumber} setCardNumber={setCardNumber} setCvcNumber={setCvcNumber} setCardYear={setCardYear} setCardMonth={setCardMonth} setAlternateCardDetailVisibility={setAlternateCardDetailVisibility} />  */}
                <PaymentStripModuleAlternateCard setAlternateCardToken={setAlternateCardToken}  setAlternateCardDetailVisibility={setAlternateCardDetailVisibility}/>
            </Dialog>
        </>
    );
}
