import React, { useState } from "react";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import Axios from "axios";
import { ToastContainer } from "react-toastify"; // Import ToastContainer and toast
import { toast } from "react-toastify";
import { InputText } from "primereact/inputtext";
import classNames from "classnames";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const CardAuthPayment = ({
    parentDetails,
    initiationData,
    currentBillingModel,
    setPreviewScreenActive,
    setPaymentScreenActive,
    setAllPaymentInfo,
    setDataSendForInvoice,
    paymentScreenDetails,
    allplans,
    plan,
    clientSecret,
    paymentDone,
    currentBillingConfig,
    paid,
    object,
    setActiveIndex,
    setPaymentDialogVisibility,
    setPaymentDone,
    setPaymentScreenDetails,
}) => {
    const [formattedCardNumber, setFormattedCardNumber] = useState("");
    const [cvc, setCvc] = useState("");
    const basicData = localStorage.getItem("basicData");
    const parsebasicData = JSON.parse(basicData);
    const userDetails = parsebasicData?.data;
    const [isLoading, setIsLoading] = useState(false);
    const validationSchema = Yup.object().shape({
        cardNumber: Yup.string().required("Please Enter Card Number"),
        cardCode: Yup.string().required("Please Enter CVC"),
        expirationDate: Yup.string().required("Please select Exp Date"),
        totalAmount: Yup.string().required("Please Select Amount"),
    });
    let checkLabel;
    try {
        const storedItem = localStorage.getItem("checked");
        checkLabel = storedItem ? JSON.parse(storedItem) : false;
    } catch (error) {
        console.error("Error parsing checkLabel from localStorage", error);
        checkLabel = false;
    }
    const loginRes = localStorage.getItem("userData");
    const parseLoginRes = JSON.parse(loginRes);
    const shipmentName = localStorage.getItem("shipmentName");
    const shipmentCost = localStorage.getItem("shipmentCost");
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            totalAmount: "",
            cardNumber: "",
            cardCode: "",
            expirationDate: "",
            amount: parseFloat(object.totalamount).toFixed(2),
        },

        onSubmit: async (values, actions) => {
            setIsLoading(true);
            let serviceprovider = JSON.parse(localStorage.getItem("userData")).company;
            let Modules = JSON.parse(localStorage.getItem("permissions"));
            let moduleid;
            for (let i = 0; i < Modules.length; i++) {
                if (Modules[i].module === "Postpaid Orders") {
                    moduleid = Modules[i].moduleId;
                }
            }
            const dataToSend = {
                amount: formik.values.totalAmount,
                cardNumber: formattedCardNumber.replace(/-/g, ""),
                cardCode: formik.values.cardCode,
                expirationDate: formik.values.expirationDate,
                invoiceNo: initiationData.accountId,
                customerId: initiationData?._id,
                userId: parseLoginRes?._id,
            };
            try {
                const response = await Axios.post(`${BASE_URL}/api/web/invoices/PaymentCreditCard`, dataToSend);
                if (response?.data?.data?.responseCode == "1") {
                    let additionalFeature = [];
                    let discounts = [];
                    setPaymentDone(true);
                    let dueDate = "";
                    let applyLateFee = "";
                    let oneTimeCharge = "";
                    let planName = "";
                    let planId = "";
                    let planCharges = "";
                    let selectedDiscounts = paymentScreenDetails.discount;
                    dueDate = currentBillingConfig?.dueDate;
                    oneTimeCharge = currentBillingConfig?.oneTimeCharge;
                    applyLateFee = currentBillingConfig?.applyLateFee;
                    for (let i = 0; i < currentBillingConfig?.selectdiscount?.length; i++) {
                        for (let k = 0; k < selectedDiscounts.length; k++) {
                            if (selectedDiscounts[k] === currentBillingConfig?.selectdiscount[i]?._id) {
                                let obj = {
                                    name: currentBillingConfig?.selectdiscount[i]?.discountname,
                                    amount: currentBillingConfig?.selectdiscount[i]?.amount,
                                };
                                discounts.push(obj);
                            }
                        }
                    }
                    for (let i = 0; i < allplans?.length; i++) {
                        if (object.plan === allplans[i]?._id) {
                            planName = allplans[i]?.name;
                            planCharges = allplans[i]?.price;

                            planId = allplans[i]?._id;
                        }
                    }

                    let additionalFeatures = paymentScreenDetails?.additional;
                    for (let k = 0; k < additionalFeatures?.length; k++) {
                        for (let i = 0; i < currentBillingConfig?.additionalFeature?.length; i++) {
                            if (currentBillingConfig?.additionalFeature[i]?._id === additionalFeatures[k]) {
                                let obj = {
                                    name: currentBillingConfig?.additionalFeature[i]?.featureName,
                                    amount: currentBillingConfig?.additionalFeature[i]?.featureAmount,
                                };
                                additionalFeature.push(obj);
                            }
                        }
                    }

                    if (currentBillingModel === "POSTPAID") {
                        const dataToSend = {
                            invoiceType: "Sign Up",
                            customerId: initiationData?._id,
                            planId: object.plan,
                            paymentId: response?.data?.data?.transId,
                            additionalCharges: object.additional,
                            discount: discounts,
                            totalAmount: parseFloat(object.totalamount).toFixed(2),
                            amountPaid: formik.values.totalAmount,
                            billingPeriod: {
                                from: "onActivation",
                                to: "onActivation",
                            },
                            invoiceStatus: parseFloat(object.totalamount) <= parseFloat(formik.values.totalAmount) ? "Paid" : parseFloat(object.totalamount) > parseFloat(formik.values.totalAmount) && parseFloat(formik.values.totalAmount) > 0 ? "Partial" : "Unpaid",
                            paymentMethod: "Credit Card",
                            chargingType: "Monthly",
                            printSetting: "Both",

                            isGenerateInvoiceWithParent: object?.isGenerateInvoiceWithParent,
                            selectProduct: object.billId,
                            isShipmentCostDiscounted: checkLabel,
                            shipmentCost: {
                                name: shipmentName,
                                amount: shipmentCost,
                            },
                        };

                        setDataSendForInvoice(dataToSend);
                        try {
                            const response = await Axios.post(`${BASE_URL}/api/user/postpaidpaymentDetails`, dataToSend);
                            if (response?.status === 201 || response?.status === 200) {
                                setAllPaymentInfo(response?.data);
                                setPaymentScreenActive(false);
                                setPreviewScreenActive(true);
                            }
                        } catch (error) {
                            toast.error(error?.response?.data?.msg);
                            setIsLoading(false);
                        }
                        setIsLoading(false);
                    } else {
                        const loginRes = localStorage.getItem("userData");
                        const parseLoginRes = JSON.parse(loginRes);

                        let dataToSend = {
                            paymentId: response?.data.data?.transId,
                            isAutopay: false,
                            customerId: object.customerid,
                            invoiceType: "Sign Up",
                            totalAmount: object.totalamount,
                            additionalCharges: additionalFeature,
                            discount: discounts,
                            amountPaid: formik.values.totalAmount,
                            selectProduct: object?.billId,
                            invoiceDueDate: dueDate,
                            isGenerateInvoiceWithParent: object?.isGenerateInvoiceWithParent,
                            lateFee: applyLateFee,
                            invoiceOneTimeCharges: oneTimeCharge,
                            invoiceStatus: parseFloat(object.totalamount) <= parseFloat(formik.values.totalAmount) ? "Paid" : parseFloat(object.totalamount) > parseFloat(formik.values.totalAmount) && parseFloat(formik.values.totalAmount) > 0 ? "Partial" : "Unpaid",
                            planId: plan,
                            planName: planName,
                            planCharges: planCharges,
                            chargingType: "monthly",
                            invoicePaymentMethod: object?.paymentMode,
                            printSetting: "Both",
                            isInvoice: true,
                            userId: parseLoginRes?._id,
                            billingPeriod: {
                                from: "onActivation",
                                to: "onActivation",
                            },
                            isShipmentCostDiscounted: checkLabel,
                            shipmentCost: {
                                name: shipmentName,
                                amount: shipmentCost,
                            },
                        };

                        setDataSendForInvoice(dataToSend);
                        Axios.post(`${BASE_URL}/api/web/invoices/prepaidgenerateInvoice`, dataToSend)
                            .then((response) => {
                                setPaymentScreenDetails((prev) => ({ ...prev, paid: formik.values.totalAmount }));

                                setAllPaymentInfo(response?.data);
                                setPaymentScreenActive(false);
                                setPreviewScreenActive(true);
                            })
                            .catch((err) => {});
                    }
                    toast.current.show({ severity: "success", summary: "Payment Processed", detail: response?.data?.data?.messages[0]?.description });
                } else {
                    if (response?.data?.data?.responseCode === "2") {
                        toast.current.show({ severity: "error", summary: "Payment Proceed", detail: response?.data?.data?.errors[0]?.errorText });
                    }
                }
            } catch (error) {
                toast.current.show({ severity: "error", summary: "Payment Proceed", detail: "Payment Proceeding Failed" });
                setIsLoading(false);
            }
            setIsLoading(false);
        },
    });

    const formatExpirationDate = (value) => {
        value = value.replace(/\D/g, "");
        if (value.length > 4) {
            value = value.slice(0, 4) + "-" + value.slice(4);
        }
        return value;
    };

    // const formatCardNumber = (value) => {
    //     return value
    //         .replace(/\D/g, "")
    //         .replace(/(.{4})/g, "$1-") // Insert hyphens after every 4 digits
    //         .trim()
    //         .slice(0, 19); // Limit to 19 characters
    // };
    const formatCardNumber = (value) => {
        // Remove all non-digit characters
        const cleanedValue = value.replace(/\D/g, "");

        // Check if the card is AMEX card (starts with '34' or '37')
        if (cleanedValue.startsWith("34") || cleanedValue.startsWith("37")) {
            setCvc("AMEX");
            // Format as AMEX: `3700-000000-00002`
            return cleanedValue
                .replace(/(\d{4})(\d{6})(\d{0,4})/, "$1-$2-$3") // Apply AMEX format
                .trim()
                .slice(0, 17); // Limit to 17 characters (15 digits + 2 dashes)
        } else {
            setCvc("OTHER");
            // Format as other cards: `4242-4242-4242-4242`
            return cleanedValue
                .replace(/(.{4})/g, "$1-") // Insert hyphens every 4 digits
                .trim()
                .slice(0, 19); // Limit to 19 characters (16 digits + 3 dashes)
        }
    };

    const handleCardNumberChange = (e) => {
        const formattedValue = formatCardNumber(e.target.value);
        setFormattedCardNumber(formattedValue);
        formik.setFieldValue("cardNumber", e.target.value.replace(/-/g, "")); // Remove hyphens before storing in formik state
    };
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    return (
        <>
            <form onSubmit={formik.handleSubmit}>
                <ToastContainer />
                <div className="col-12  ">
                    <div className="p-3">
                        <div className=" h-full ">
                            <div>
                                <table className="cp_table w-full">
                                    <tbody>
                                        <tr className="text-lg">
                                            <td>
                                                Total Amount: <span className="steric">*</span>
                                            </td>
                                            <td>
                                                {" "}
                                                <InputText type="text" id="amount" value={formik.values.amount} onChange={formik.handleChange} disabled />
                                            </td>
                                        </tr>
                                        <tr className="text-lg">
                                            <td>
                                                Amount <span className="steric">*</span>
                                            </td>
                                            <td>
                                                {" "}
                                                <InputText type="text" id="totalAmount" value={formik.values.totalAmount} onChange={formik.handleChange} className={classNames({ "p-invalid": isFormFieldValid("totalAmount") }, "input_text")} />
                                                {getFormErrorMessage("totalAmount")}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td className="text-lg">
                                                Card Number <span className="steric">*</span>
                                            </td>
                                            <td>
                                                <InputText className={classNames({ " mr-3": true, "p-invalid": isFormFieldValid("cardNumber") }, "input_text")} type="text" id="cardNumber" value={formattedCardNumber} onChange={handleCardNumberChange} />
                                                {getFormErrorMessage("cardNumber")}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-lg">
                                                CVC <span className="steric">*</span>
                                            </td>
                                            <td>
                                                <InputText keyfilter="int" className={classNames({ " mr-3": true, "p-invalid": isFormFieldValid("cardCode") }, "input_text")} type="text" id="cardCode" value={formik.values.cardCode} maxLength={cvc === "AMEX" ? 4 : 3} onChange={formik.handleChange} />
                                                {getFormErrorMessage("cardCode")}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="text-lg">
                                                Exp Date <span className="steric">*</span>
                                            </td>
                                            <InputText
                                                className={classNames({ " ml-3": true, "p-invalid": isFormFieldValid("expirationDate") }, "input_text")}
                                                type="text"
                                                id="expirationDate"
                                                maxLength={7}
                                                placeholder="YYYY-MM"
                                                value={formatExpirationDate(formik.values.expirationDate)}
                                                onChange={(e) => {
                                                    const formattedValue = formatExpirationDate(e.target.value);
                                                    formik.setFieldValue("expirationDate", formattedValue);
                                                }}
                                            />
                                        </tr>
                                        <div className="mt-2">
                                            <Button label="Submit" type="submit" disabled={isLoading} icon={isLoading === true ? "pi pi-spin pi-spinner " : ""} />
                                        </div>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
};
export default CardAuthPayment;
