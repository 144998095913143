import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Axios from "axios";
import { Button } from "primereact/button";
import { toast, ToastContainer } from "react-toastify";
import { Dialog } from "primereact/dialog";
import "../../../../../../app.css"
import { InputText } from "primereact/inputtext";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const PaymentLogs = ({ userDetails }) => {
    const [data, setData] = useState([]);
    const [loadingRowId, setLoadingRowId] = useState(null);
    const [expandedRows, setExpandedRows] = useState(null);
    const [showDialog, setShowDialog] = useState(false);
    const [amount, setAmount] = useState("");
    const [isRowData, setIsRowData] = useState();
    const [refersh, setRefersh] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const loginRes = localStorage.getItem("userData");
    const parseLoginRes = JSON.parse(loginRes);
    const companyName = parseLoginRes?.companyName;


    const rowClassName = (rowData) => {
        if (rowData.invoiceVoid) {
            return "void-invoice";
        }
        return "";
    };

    const processPaymentLogs = (data) => {
        // Group the data by invoiceNo
        const groupedData = data.reduce((acc, item) => {
            const key = item.invoiceNo;
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(item);
            return acc;
        }, {});
    
        // Determine the latest status for each transaction ID
        const latestTransactions = {};
        Object.values(groupedData).forEach(group => {
            group.forEach(transaction => {
                const transactionId = transaction.transactionId;
                if (!latestTransactions[transactionId] || new Date(latestTransactions[transactionId].date) < new Date(transaction.date)) {
                    latestTransactions[transactionId] = transaction;
                }
            });
        });
    
        // Mark only the latest transaction's button as enabled
        Object.values(groupedData).forEach(group => {
            group.forEach(transaction => {
                transaction.isLatest = (transaction === latestTransactions[transaction.transactionId]);
            });
        });
    
        // Flatten the grouped data back to an array
        return Object.values(groupedData).map(group => ({
            ...group[0], // First object
            remaining: group.slice(1), // Remaining objects
        }));
    };
    
    const getPaymentLogs = async () => {
        try {
            const response = await Axios.get(`${BASE_URL}/api/web/invoices/paymentlogs?customerId=${userDetails?._id}`);
    
            if (response?.status === 200 || response?.status === 201) {
                // Process the data to mark the latest transaction for each ID
                const processedData = processPaymentLogs(response.data);
                setData(processedData);
            } else {
                console.error("Unexpected response status:", response?.status);
            }
        } catch (error) {
            console.error("Error fetching Payment logs:", error);
        }
    };
    

    const renderActionButtons = (rowData) => {
        const transactionStatus = rowData.transactionStatus;
        const lastStatus = transactionStatus; // Directly use the status from the rowData
        const isLatest = rowData.isLatest; // Check if this is the latest status for the transaction
    
        const voidStatuses = ["authorizedPendingCapture", "capturedPendingSettlement", "settlementError", "requires_capture"];
        const refundStatuses = ["settledSuccessfully", "succeeded", "processing"];
        const disabledStatuses = [
            "communicationError",
            "refundSettledSuccessfully",
            "declined",
            "couldNotVoid",
            "expired",
            "generalError",
            "failedReview",
            "underReview",
            "voided",
            "FDSPendingReview",
            "FDSAuthorizedPendingReview",
            "returnedItem",
            "Successful.",
            "refunded",
            "requires_payment_method",
            "requires_action",
            "canceled",
            "requires_payment_method",
        ];
    
        if (voidStatuses.includes(lastStatus) && isLatest) {
            return (
                <Button 
                    style={{ fontWeight: "600" }} 
                    className="btn pt-2 void-btn-p pb-2 border-none" 
                    onClick={() => voidPayment(rowData)} 
                    icon={loadingRowId === rowData.transactionId ? "pi pi-spin pi-spinner" : ""} 
                    disabled={loadingRowId === rowData.transactionId}
                >
                    Void
                </Button>
            );
        }
    
        if (refundStatuses.includes(lastStatus) && isLatest) {
            return (
                <Button
                    style={{ fontWeight: "600" }}
                    className="btn pt-2 pl-4 pr-5 pb-2 border-none"
                    onClick={() => {
                        setShowDialog(true);
                        setIsRowData(rowData);
                    }}
                    icon={loadingRowId === rowData.transactionId ? "pi pi-spin pi-spinner" : ""}
                    disabled={loadingRowId === rowData.transactionId}
                >
                    Refund
                </Button>
            );
        }
    
        if (disabledStatuses.includes(lastStatus)) {
            return (
                <Button 
                    style={{ fontWeight: "600" }} 
                    className="btn pt-2 pl-2 pr-2 pb-2 border-none" 
                    disabled
                >
                    Refund/Void
                </Button>
            );
        }
        return null;
    };
    

    useEffect(() => {
        getPaymentLogs();
        setRefersh(false);
    }, [refersh]);

    const formatDate = (dateString) => {
        const options = { year: "numeric", month: "2-digit", day: "2-digit" };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const voidPayment = async (rowData) => {
        setLoadingRowId(rowData.transactionId);
        const dataToSend = {
            transactionIdToVoid: rowData?.transactionId,
            customerId: userDetails?._id,
            userId: parseLoginRes?._id,
        };
        const dataToSend1 = {
            transactionId: rowData?.transactionId,
            customerId: userDetails?._id,
            userId: parseLoginRes?._id,
        };
        if (companyName.includes("IJ Wireless")) {
            try {
                const response = await Axios.post(`${BASE_URL}/api/web/billing/voidCharge`, dataToSend1);
                if (response?.status === 200 || response?.status === 201) {
                    toast.success("Void Successfully");
                    setRefersh(true);
                }
            } catch (error) {
                toast.error(error?.response?.data?.msg);
            } finally {
                setLoadingRowId(null);
            }
        } else {
            try {
                const response = await Axios.post(`${BASE_URL}/api/web/invoices/voidTransaction`, dataToSend);
                if (response?.status === 200 || response?.status === 201) {
                    toast.success("Void Successfully");
                    setRefersh(true);
                }
            } catch (error) {
                toast.error(error?.response?.data?.msg);
            } finally {
                setLoadingRowId(null);
            }
        }
    };

    const handleYesClick = async () => {
        if (amount > 0 && amount == isRowData?.amount) {
            setIsLoading(true)
            setLoadingRowId(isRowData.transactionId);
            const dataToSend = {
                transactionIdToRefund: isRowData?.transactionId,
                customerId: userDetails?._id,
                userId: parseLoginRes?._id,
                cardNumber: isRowData?.cardDetails.cardNumber.slice(-4),
                expirationDate: "XXXX",
                amount: amount,
            };
            const dataToSend1 = {
                transactionId: isRowData?.transactionId,
                customerId: userDetails?._id,
                userId: parseLoginRes?._id,
                amount: amount,
            };
            if (companyName.includes("IJ Wireless")) {
                try {
                    const response = await Axios.post(`${BASE_URL}/api/web/billing/refundCharge`, dataToSend1);
                    if (response?.status === 200 || response?.status === 201) {
                        toast.success(response?.data?.msg);
                        setIsLoading(false)
                        setShowDialog(false);
                        setRefersh(true);
                    }
                } catch (error) {
                    toast.error(error?.response?.data?.msg);
                    setIsLoading(false)
                } finally {
                    setLoadingRowId(null);
                    setIsLoading(false)
                }
            } else {
                try {
                    const response = await Axios.post(`${BASE_URL}/api/web/invoices/refundTransaction`, dataToSend);
                    if (response?.status === 200 || response?.status === 201) {
                        toast.success(response?.data?.data?.message[0]?.text);
                        setIsLoading(false)
                        setShowDialog(false);
                        setRefersh(true);
                    }
                } catch (error) {
                    toast.error(error?.response?.data?.msg);
                    setIsLoading(false)
                } finally {
                    setLoadingRowId(null);
                    setIsLoading(false)
                }
                setIsLoading(false)
            }
        } else {
            toast.error("Please enter valid  amount to refund");
        }
    };

    const handleNoClick = () => {
        setShowDialog(false);
    };
    
    const rowExpansionTemplate = (rowData) => {
        return (
            <div style={{marginTop:'-13px'}} >
                <DataTable  value={rowData.remaining} className="custom-datatable ml"  rowClassName={rowClassName} scrollable
              scrollDirection="horizontal">
                    <Column  field="invoiceNo" style={{ width: '13.5rem' }} />
                    <Column  field="invoiceType" style={{ width: '11rem' }}  />
                    <Column field="paymentType" style={{ width: '10.5rem' }} />
                    <Column field="cardDetails.cardNumber" style={{ width: '15rem' }} />
                    <Column field="amount" style={{ width: '9rem' }} />
                    <Column field="paymentBy" style={{ width: '9rem' }} />
                    <Column field="transactionStatus" style={{ width: '16rem' }} />
                    <Column field="paymentStatus" style={{ width: '10rem' }}  />
                    <Column field="transactionId"  />
                    <Column field="date"  body={(rowData) => formatDate(rowData.date)} style={{ width: '10rem' }} />
                    <Column field="Action"  body={renderActionButtons} />
                </DataTable>
            </div>
        );
    };

    return (
        <>
            <div>
                <ToastContainer />
                <DataTable value={data} expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)} rowExpansionTemplate={rowExpansionTemplate} tableStyle={{ minWidth: '60rem' }} dataKey="invoiceNo" rowClassName={rowClassName} scrollable
                scrollDirection="horizontal">
                    <Column expander style={{ width: "3em" }} />
                    <Column field="invoiceNo" header="Invoice No" />
                    <Column field="invoiceType" header="Invoice Type" style={{width:"8em"}} />
                    <Column field="paymentType" header="Payment Type" />
                    <Column field="cardDetails.cardNumber" header="Card Details"/>
                    <Column field="amount" header="Amount" style={{width:"8rem"}}/>
                    <Column field="paymentBy" header="Payment By" />
                     <Column field="transactionStatus" header="Transaction Status"/>
                    <Column field="paymentStatus" header="Payment Status"/>
                    <Column field="transactionId" header="Transaction ID"/>
                    <Column field="date" header="Date" body={(rowData) => formatDate(rowData.date)} />
                    <Column field="Action" header="Action" body={renderActionButtons} />
                </DataTable>

            </div>
            <Dialog
                visible={showDialog}
                style={{ width: "30vw" }}
                modal
                footer={
                    <>
                        <Button label="Yes" onClick={handleYesClick} icon={isLoading === true ? "pi pi-spin pi-spinner " : ""} disabled={isLoading} />
                        <Button label="No" onClick={handleNoClick} className="p-button-secondary" />
                    </>
                }
                onHide={() => setShowDialog(false)}
            >
                <div className="reason">
                    <p>
                        Amount <span className="ml-5">:</span>
                    </p>
                    <InputText type="text" className="w-21rem reason_text" placeholder="Enter amount" value={amount} id="amount" onChange={(e) => setAmount(e.target.value)} />
                </div>
            </Dialog>
        </>
    );
};

export default PaymentLogs;



