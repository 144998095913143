import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useRef, useState } from "react"; 
import * as Yup from "yup" 
import Axios  from "axios";
import "./css/Change_Customer_Plans.css"
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast"
export default function ChangeCustomerPlan({setRefresh,setChangeCustomerPlan,customerId}){ 
    const [allPans,setAllPlans]=useState([])
    const toast=useRef()
    let parseLogin=JSON.parse(localStorage.getItem("userData")) 
      const [loading,setLoading]=useState(false)
    const formik = useFormik({
        initialValues: {
            enrollmentId: customerId,
            newPlanId: "",
        },
        validationSchema: Yup.object({
            newPlanId: Yup.string().required("Plan Is Required"),
        }),
        onSubmit: async (values) => {   
            setLoading(true)
            Axios.post(`${process.env.REACT_APP_BASE_URL}/api/user/changePlanPwg`,values).then(res=>{ 
                //setAllPlans(res?.data?.data) 
                toast.current.show({ severity: "success", summary: "Info", detail: "Plan Updated Successfully" });
      
              setLoading(false)  
               setTimeout(()=>{ 

                setChangeCustomerPlan(false) 
                setRefresh(prev=>!prev)
               },1000)
            }).catch(err=>{ 
                toast.current.show({ severity: "error", summary: "Info", detail: err?.response?.data?.status?.errors?.error?.message });
      
       setLoading(false)
            })
        }}) 
        useEffect(()=>{ 
        Axios.get(`${process.env.REACT_APP_BASE_URL}/api/web/plan/all`,{params:{serviceProvider:parseLogin?.company}}).then(res=>{ 
            setAllPlans(res?.data?.data)
        }).catch(err=>{ 

        })
           
        },[])
    return( 
        <form onSubmit={formik.handleSubmit}> 
          <label className="w-full label-choose-plan block">Choose Plan <span className="asterisk">*</span></label> 
           <Dropdown placeholder="Choose Plan" className="mt-4 choose-plan-dropdown" optionLabel="name" optionValue="planId" value={formik.values.newPlanId} name="newPlanId" onChange={formik.handleChange} options={allPans} />
           {formik.touched.newPlanId && formik.errors.newPlanId ? (
                                    <p style={{ height: "10px" }} className="text-red-500 p-error ml-1 text-sm mt-0 ">
                                        {formik.errors.newPlanId}
                                    </p>
                                ) : null}
            <Button  disabled={loading} type="submit" className="btn block mt-4"  label="Submit"/>
          <Toast ref={toast} />
            </form>
     )
}