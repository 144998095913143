import { InputText } from "primereact/inputtext";
import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import classNames from "classnames";
import Axios from "axios";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify"; // Import ToastContainer and toast
import "react-toastify/dist/ReactToastify.css"; // Import toast styles

const BASE_URL = process.env.REACT_APP_BASE_URL;

const ShowLateFee =({singleInvoiceData,setSelectManage,setShowLateFee,setRefresh,userDetails})=>{
 
    const userData = localStorage.getItem("userData");
  const parseuserData = userData ? JSON.parse(userData) : {};

    const [showDialog, setShowDialog] = useState(false); 
    const validationSchema = Yup.object().shape({
        reason: Yup.string().required("This is Required"),
    })
    const formik = useFormik({
        validationSchema:validationSchema,
        initialValues :{
            reason:"",
        },
        onSubmit: async ()=>{
             
              const rmSpace = formik.values.reason.trim();
              if(rmSpace.length>1){
                setShowDialog(true)
              }
              else{
                toast.error("Please Enter Valid Reason")
              }
        }
    })
    

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };
    const handleYesClick = async() => {
        const data ={
            customerId:userDetails?._id,
            userId:parseuserData?._id,
            invoiceId:singleInvoiceData?._id,
            reason:formik.values.reason
        }
        try {
            const resp = await Axios.put(`${BASE_URL}/api/web/invoices/waiveLateFee`,data)
            if(resp?.status===200 || resp?.status===201){
                toast.success(resp?.data?.msg)
                setTimeout(()=>{
                    setShowDialog(false)
                    setShowLateFee(false)
                    setRefresh(true)
                },1000)
            }
        } catch (error) {
            toast.error(error?.response?.data?.msg)
            setTimeout(()=>{
                setShowDialog(false)  
            },1000)
        }
      };
    
      const handleNoClick = () => {    
        setSelectManage("")
        setShowDialog(false);
        setShowLateFee(false);
      };

    return(
        <>
         <form onSubmit={formik.handleSubmit}>
        <div>
            <div className="invoiceNum">
                <p>Invoice NO : </p>
                <p className="ml-5">{singleInvoiceData?.invoiceNo}</p>
            </div>
            <div className="reason">
                <p>
                    Reason <span className="ml-5">:</span>
                </p>
                <InputText   className={classNames({ "p-invalid": isFormFieldValid("reason") }, " w-21rem reason_text")} placeholder="Enter Reason" value={formik.values.reason} id="reason"   onChange={formik.handleChange}/>
                {getFormErrorMessage("reason")}
            </div>
            <div className="void-btn">
                <Button label="Waive Off Late Fee" type="submit"  className="btn-p"/>
            </div>
        </div>
        </form>

        <Dialog
        header="Warning"
        visible={showDialog}
        style={{ width: '30vw' }}
        modal
        footer={
          <>
            <Button label="Yes" onClick={handleYesClick} />
            <Button label="No" onClick={handleNoClick} className="p-button-secondary" />
          </>
        }
        onHide={() => setShowDialog(false)}
      >
        <p>Are you sure you want to waive the late fee for this invoice?</p>
      </Dialog>
        </>
    )
}
export default ShowLateFee