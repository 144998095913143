import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button } from "primereact/button";
import { useState } from "react";
import { Dialog } from "primereact/dialog";      
import "./css/service-eligibility-personal-info.css"
import CommonLoaderBlue from "../../../../components/Loader/LoaderBlue";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import Axios from "axios";
const BASE_URL = process.env.REACT_APP_BASE_URL;
export default function Post_service_availbilty() {
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();
    const [isCreate, setIsCreate] = useState(false);
    const [pwgApiCheck, setPwgApiCheck] = useState("pending");
    const [pwgApiCheckFound, setPwgApiCheckFound] = useState(false);
    const [pwgDbCheck, setPwgDbCheck] = useState("pending");
    const [pwgDbCheckFound, setPwgDbCheckFound] = useState(false);
    const [uspsCheck, setUspsCheck] = useState("pending");
    const [uspsCheckFound, setUspsCheckFound] = useState(false);
    const [showCheckCoverage, setShowCheckCoverage] = useState(false);
    const [isManage, setIsManage] = useState(false);
    const location = useLocation();
    const currentPath = location?.pathname;    
    const BASE_URL = process.env.REACT_APP_BASE_URL; 
    const loginRes = localStorage.getItem("userData");
    const parseLoginRes = JSON.parse(loginRes);   
    function cleanLocalStorage() {
        localStorage.removeItem("basicData");
        localStorage.removeItem("address");
        localStorage.removeItem("toWordsBack");
        localStorage.removeItem("agreeData");
        localStorage.removeItem("programmeId");
        localStorage.removeItem("fromIncomplete");
        localStorage.removeItem("productData");
        localStorage.removeItem("paymentstatus");
        localStorage.removeItem("paymentInfo");
        localStorage.removeItem("dataToSend");
        localStorage.removeItem("checkEligiblity");
        localStorage.removeItem("fromRejected");
    }
    const actionBasedChecks = () => {
        const loginPerms = localStorage.getItem("permissions");
        const parsedLoginPerms = JSON.parse(loginPerms);

        const isCreate = parsedLoginPerms.some((node) => node?.subModule.some((subNode) => subNode?.route === currentPath && subNode?.actions.some((action) => action?.name === "create")));
        setIsCreate(isCreate);

        const isManage = parsedLoginPerms.some((node) => node?.subModule.some((subNode) => subNode?.route === currentPath && subNode?.actions.some((action) => action?.name === "manage")));
        setIsManage(isManage);
    };

    useEffect(() => {
        actionBasedChecks();     
        Axios.get(`${BASE_URL}/api/web/billing/StatusBillingModel`,{ 
            params:{ billingmodel:"POSTPAID",  
             serviceProvider:parseLoginRes?.company 
            }
         }).then((res)=>{    
      
        setCheckBilling(res?.data?.success ===  "false" ? false :true)         
        setCheckbillingapicalled(true)   
         
         }).catch(err=>{ 
              
         })
    }, []);

    // Get user data from localStorage
  

    useEffect(() => {
        localStorage.removeItem("zipData");
    }, []);

    const validationSchema = Yup.object().shape({
        zipCode: Yup.string().required("Please enter Zip code"),
    });

    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            zipCode: "",
        },
        onSubmit: async (values, actions) => {
            cleanPayment();
            const serviceProvider = parseLoginRes?.company;
            const department = parseLoginRes?.department;

            const csr = parseLoginRes?._id;
            const carrier = "6455532566d6fad6eac59e34";
            const accountType = "Postpaid";
            const dataToSend = { serviceProvider, csr, department, carrier, accountType, ...values };
            setIsLoading(true);
            setShowCheckCoverage(true);
            Axios.post(`${BASE_URL}/api/user/PWGverifyZip`, dataToSend)
                .then((res) => {
                    setPwgApiCheck(false);
                    setPwgApiCheckFound(true);
                    localStorage.setItem("zipData", JSON.stringify(res.data));
                    Axios.post(`${BASE_URL}/api/user/DBverifyzip`, dataToSend)
                        .then((res) => {
                            setPwgDbCheck(false);
                            setPwgDbCheckFound(true);
                            Axios.post(`${BASE_URL}/api/user/verifyZip`, dataToSend)
                                .then((res) => {
                                    cleanLocalStorage();
                                    setUspsCheck(false);
                                    setUspsCheckFound(true);
                                    setIsLoading(false);
                                })
                                .catch((err) => {
                                    setUspsCheck(false);
                                    setUspsCheckFound(false);
                                    setIsLoading(false);
                                });
                        })
                        .catch((err) => {
                            setPwgDbCheck(false);
                            setPwgDbCheckFound(false);
                            Axios.post(`${BASE_URL}/api/user/verifyZip`, dataToSend)
                                .then((res) => {
                                    cleanLocalStorage();
                                    setUspsCheck(false);
                                    setUspsCheckFound(true);
                                })
                                .catch((err) => {
                                    setUspsCheck(false);
                                    setUspsCheckFound(false);
                                });
                        });
                })
                .catch((err) => {
                    setPwgApiCheck(false);
                    setPwgApiCheckFound(false);
                    Axios.post(`${BASE_URL}/api/user/DBverifyzip`, dataToSend)
                        .then((res) => {
                            setPwgDbCheck(false);
                            setPwgDbCheckFound(true);
                            localStorage.setItem("zipData", JSON.stringify(res.data));
                            Axios.post(`${BASE_URL}/api/user/verifyZip`, dataToSend)
                                .then((res) => {
                                    cleanLocalStorage();
                                    setUspsCheck(false);
                                    setUspsCheckFound(true);
                                    setIsLoading(false);
                                })
                                .catch((err) => {
                                    setUspsCheck(false);
                                    setUspsCheckFound(false);
                                    setIsLoading(false);
                                });
                        })
                        .catch((err) => {
                            setPwgDbCheck(false);
                            setPwgDbCheckFound(false);
                            Axios.post(`${BASE_URL}/api/user/verifyZip`, dataToSend)
                                .then((res) => {
                                    cleanLocalStorage();
                                    setUspsCheck(false);
                                    setUspsCheckFound(true);
                                    setIsLoading(false);
                                    localStorage.setItem("zipData", JSON.stringify(res.data));
                                })
                                .catch((err) => {
                                    setUspsCheck(false);
                                    setUspsCheckFound(false);
                                    setIsLoading(false);
                                });
                        });
                });
            /* setIsLoading(true);
     try {
                const response = await Axios.post(`${BASE_URL}/api/user/verifyZip`, dataToSend);

                if (response?.status === 200) {
                    localStorage.setItem("prepaidzipData", JSON.stringify(response.data)); 
              
                    setZipVerified(true);
                    setShowCheckCoverage(true)
                }
            } catch (error) {
                setErrorMessage(error?.response?.data?.msg);
                setIsLoading(false);
            }   
                 */
        },
    });

    //     setIsLoading(true);
    //         try {
    //             const response = await Axios.post(`${BASE_URL}/api/user/verifyZip`, dataToSend);

    //             if (response?.status === 200) {
    //                 localStorage.setItem("zipData", JSON.stringify(response.data));
    //                 localStorage.removeItem("basicData");
    //                 localStorage.removeItem("address");
    //                 localStorage.removeItem("toWordsBack");
    //                 localStorage.removeItem("agreeData");
    //                 localStorage.removeItem("programmeId");
    //                 localStorage.removeItem("fromIncomplete");
    //                 localStorage.removeItem("productData");
    //                 localStorage.removeItem("paymentstatus");
    //                 localStorage.removeItem("paymentInfo");
    //                 localStorage.removeItem("dataToSend");
    //                 localStorage.removeItem("checkEligiblity");
    //                 localStorage.removeItem("fromRejected");
    //
    //             }
    //         } catch (error) {
    //             setErrorMessage(error?.response?.data?.msg);
    //             setIsLoading(false);
    //         }
    //     },
    // });
    const cleanPayment = () => {
        localStorage.removeItem("paymentstatus");
        localStorage.removeItem("skippayment");
    };   
    const [checkBilling,setCheckBilling]=useState(false)   
    const [billingCheckapicalled,setCheckbillingapicalled]=useState(false)    
    
    return (         
           <>   {
                  !billingCheckapicalled  ?   
           <div className="main-container-loader flex flex-wrap  pt-4 pb-4 flex-row justify-content-center align-items-center">
        <CommonLoaderBlue/>   
           </div>
         : billingCheckapicalled && !checkBilling ?   <div className="main-container-error"><p  className="text-center text-red-500">Setup Billing Configuration For This Module</p>   </div>  :
        <div className="flex flex-column justify-content-center">
            <div className="flex justify-content-center align-content-center my-5">
                <div className="cardnewenrollment " style={{ height: "5%" }}>
                    <h1 className="heading">NEW ENROLLMENT</h1>
                    <hr className="hr" />
                    <form className="my-4" onSubmit={formik.handleSubmit}>
                        <p style={{ fontFamily: "Inter", fontSize: "16px", fontWeight: "500", paddingLeft: "20px", color: "black" }}>Please Enter The Zip Code To Check Service Availability</p>

                        {isLoading ? (
                            <InputText
                                type="text"
                                name="zipCode"
                                className="col-11 mb-3"
                                value={formik.values.zipCode}
                                style={{ marginLeft: "20px", width: "900px" }}
                                onChange={(e) => {
                                    formik.setFieldValue("zipCode", e.target.value);
                                    if (pwgApiCheck !== "pending" && pwgDbCheck !== "pending" && uspsCheck !== "pending") {
                                        formik.setFieldValue("zipCode", e.value);
                                        setShowCheckCoverage(false);
                                        setIsLoading(false);
                                        setPwgApiCheck("pending");
                                        setPwgApiCheckFound(true);
                                        setPwgDbCheck("pending");
                                        setPwgDbCheckFound(true);
                                        setUspsCheck("pending");
                                        setUspsCheckFound(true);
                                    }
                                }}
                                keyfilter={/^\d{0,5}$/}
                                minLength={5}
                                maxLength={5}
                                disabled={isLoading}
                            />
                        ) : (
                            <InputText
                                type="text"
                                name="zipCode"
                                className="col-11 mb-3"
                                value={formik.values.zipCode}
                                style={{ marginLeft: "20px", width: "900px" }}
                                onChange={(e) => {
                                    formik.setFieldValue("zipCode", e.target.value);
                                    if (pwgApiCheck !== "pending" && pwgDbCheck !== "pending" && uspsCheck !== "pending") {
                                        formik.setFieldValue("zipCode", e.value);
                                        setShowCheckCoverage(false);
                                        setIsLoading(false);
                                        setPwgApiCheck("pending");
                                        setPwgApiCheckFound(true);
                                        setPwgDbCheck("pending");
                                        setPwgDbCheckFound(true);
                                        setUspsCheck("pending");
                                        setUspsCheckFound(true);
                                    }
                                }}
                                keyfilter={/^\d{0,5}$/}
                                minLength={5}
                                maxLength={5}
                            />
                        )}

                        {formik.touched.zipCode && formik.errors.zipCode ? (
                            <p className="mt-0" style={{ color: "red", fontFamily: "Inter", marginLeft: "20px" }}>
                                {formik.errors.zipCode}
                            </p>
                        ) : null}
                        {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
                        {pwgApiCheck !== "pending" && pwgDbCheck !== "pending" && uspsCheck !== "pending" ? (
                            <Button
                                style={{ marginLeft: "20px" }}
                                label={"Submit"}
                                type="button"
                                onClick={() => {
                                    localStorage.setItem("izZipVerified", "yes");
                                    navigate("/post-enrollment");
                                }}
                                className="checkbutton"
                            />
                        ) : (
                            <Button style={{ marginLeft: "20px" }} label={"Check Coverage"} type="submit" className="checkbutton" disabled={isLoading || !isCreate} />
                        )}
                        {showCheckCoverage ? (
                            <div>
                                <Button
                                    style={{ marginLeft: "20px", marginTop: "20px" }}
                                    iconPos="right"
                                    className="chkbtn"
                                    type="button"
                                    icon={pwgApiCheck === "pending" ? "pi pi-spin pi-spinner" : pwgApiCheckFound ? "pi coverage-found pi-check" : "pi coverage-notfound pi-times"}
                                    label="Checking Through Pwg Apis "
                                />
                                <Button
                                    style={{ marginLeft: "20px", marginTop: "20px" }}
                                    iconPos="right"
                                    className="chkbtn"
                                    type="button"
                                    icon={pwgDbCheck === "pending" ? "pi pi-spin pi-spinner" : pwgDbCheckFound ? "pi coverage-found pi-check" : "pi coverage-notfound pi-times"}
                                    label="Checking Through Pwg Database Data "
                                />

                                <Button
                                    style={{ marginLeft: "20px", marginTop: "20px" }}
                                    iconPos="right"
                                    className="chkbtn"
                                    type="button"
                                    icon={uspsCheck === "pending" ? "pi pi-spin pi-spinner" : uspsCheckFound ? "pi coverage-found pi-check" : "pi coverage-notfound pi-times"}
                                    label="Checking Through USPS "
                                />
                            </div>
                        ) : (
                            ""
                        )}
                        <Dialog className="proceed" header="PROCEEDING CONFIRMATION" headerClassName="dialogHeader" style={{ width: "40vw" }} visible={pwgApiCheck !== "pending" && pwgDbCheck !== "pending" && uspsCheck !== "pending" && !pwgApiCheckFound && !pwgDbCheckFound && !uspsCheckFound}  onHide={() => {
                                setShowCheckCoverage(false);
                                setIsLoading(false);
                                setPwgApiCheck("pending");
                                setPwgApiCheckFound(true);
                                setPwgDbCheck("pending");
                                setPwgDbCheckFound(true);
                                setUspsCheck("pending");
                                setUspsCheckFound(true);
                            }}>
                            <p>Still Want To Proceed</p>
                            <div className="mt-4 flex flex-wrap flex-row justify-content-center">
                                <Button
                                    label="Yes"
                                    style={{ backgroundColor: "#C68301", color: "white", border: "none", height: "40px", width: "70px" }}
                                    onClick={() => {
                                        const serviceProvider = parseLoginRes?.company;
                                        const department = parseLoginRes?.department;

                                        const csr = parseLoginRes?._id;
                                        const carrier = "6455532566d6fad6eac59e34";

                                        const dataToSend = { serviceProvider, csr, department, carrier, ...formik.values, accountType: "Postpaid" };

                                        Axios.post(`${BASE_URL}/api/user/withoutzip`, dataToSend)
                                            .then((res) => {
                                                localStorage.setItem("zipData", JSON.stringify(res.data));
                                                localStorage.setItem("izZipVerified", "no");
                                                localStorage.removeItem("basicData");
                                                navigate("/post-enrollment");
                                                cleanLocalStorage();
                                            })
                                            .catch((err) => {});
                                    }}
                                />
                                <Button
                                    label="No"
                                    className="ml-4"
                                    style={{
                                        backgroundColor: "#C68301",
                                        color: "white",
                                        border: "none",
                                        height: "40px",
                                        width: "70px",
                                    }}
                                    onClick={() => {
                                        setShowCheckCoverage(false);
                                        setIsLoading(false);
                                        setPwgApiCheck("pending");
                                        setPwgApiCheckFound(true);
                                        setPwgDbCheck("pending");
                                        setPwgDbCheckFound(true);
                                        setUspsCheck("pending");
                                        setUspsCheckFound(true);
                                    }}
                                />
                            </div>
                        </Dialog>
                    </form>
                </div>
            </div>
            {/* <div className="flex flex-wrap justify-content-end"></div> */}
        </div>     
}
          </>
    );
}
