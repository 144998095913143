import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import classNames from "classnames";
import Axios from "axios";
import { ToastContainer, toast } from "react-toastify"; // Import ToastContainer and toast
import "react-toastify/dist/ReactToastify.css"; // Import toast styles
import "./Address.css";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const Address = ({isLinkedAccountIncomplete,initiationData ,setParentDetails, parentDetails,setAddressActive, setPersonalInfoActive,setConfirmationActive }) => {
    const loginRes = localStorage.getItem("userData");   
    console.log("parent Details is",parentDetails)
    const parseLoginRes = JSON.parse(loginRes);     
    const _id=initiationData?._id
    const csr = parseLoginRes?._id;
    const [confrimAddress, setConfrimAddress] = useState("same");
    const [isSame, setIsSame] = useState(parentDetails?.isSameServiceAddress);
    const [isDifferent, setIsDifferent] = useState(parentDetails?.isNotSameServiceAddress);
    const [isPoBox, setIsPoBox] = useState(parentDetails?.isPoBoxAddress);
    const [isLoading, setIsLoading] = useState(false);
    const validationSchema = Yup.object().shape({
        address1: Yup.string().required("Address is required"),
    });
    const formik = useFormik({
        validationSchema: validationSchema,
        initialValues: {
            address1: parentDetails?.address1,
            address2: parentDetails?.address2,
            zip: parentDetails?.zip,
            city:parentDetails?.city,
            state: parentDetails?.state,
            isSameServiceAddress:  parentDetails?.isSameServiceAddress,
            isNotSameServiceAddress: parentDetails?.isNotSameServiceAddress,
            isPoBoxAddress: parentDetails?.isPoBoxAddress,
            mailingAddress1:  parentDetails?.mailingAddress1,
            mailingAddress2: parentDetails?.mailingAddress2,
            mailingZip: parentDetails?.mailingZip,
            mailingCity: parentDetails?.mailingCity,
            mailingState: parentDetails?.mailingState,
            PoBoxAddress:parentDetails?.PoBoxAddress,
            poBoxZip: parentDetails?.poBoxZip,
            poBoxState:parentDetails?.poBoxState,
            poBoxCity: parentDetails?.poBoxCity,
        },
        onSubmit: async (values, actions) => {
            // checkEligiblity();
            const userId = _id;
            const dataToSend = {
                address1: formik.values.address1,
                address2: formik.values.address2,
                zip: formik.values.zip,
                city: formik.values.city,
                state: formik.values.state,
                isSameServiceAddress: formik.values.isSameServiceAddress,
                isNotSameServiceAddress: formik.values.isNotSameServiceAddress,
                isPoBoxAddress: formik.values.isPoBoxAddress,
                mailingAddress1: formik.values?.isNotSameServiceAddress  ? formik.values.mailingAddress1 : formik.values.isSameServiceAddress ? formik.values.address1 :"",
                mailingAddress2:   formik.values?.isNotSameServiceAddress  ? formik.values.mailingAddress2 : formik.values.isSameServiceAddress ? formik.values.address2 :"",
                mailingZip:  formik.values?.isNotSameServiceAddress  ? formik.values.mailingZip : formik.values.isSameServiceAddress ? formik.values?.zip :"",
                mailingCity: formik.values?.isNotSameServiceAddress  ? formik.values.mailingCity : formik.values.isSameServiceAddress ? formik.values?.city :"",
                mailingState: formik.values?.isNotSameServiceAddress  ? formik.values.mailingState : formik.values.isSameServiceAddress ? formik.values?.state :"",
                PoBoxAddress: formik.values.isPoBoxAddress ? formik.values.PoBoxAddress : "",
                poBoxZip: formik.values.isPoBoxAddress ? formik.values.poBoxZip :"",
                poBoxState:formik.values.isPoBoxAddress ? formik.values.poBoxState:"",
                poBoxCity: formik.values.isPoBoxAddress ? formik.values.poBoxCity :"",
                userId: userId,
                csr: csr,
            };

            setIsLoading(true);
            try {
                const response = await Axios.post(`${BASE_URL}/api/user/homeAddress`, dataToSend)
                if (response?.status === 200 || response?.status === 201) {
                    toast.success("Address saved Successfully"); 
                    setAddressActive(false)   
                    setConfirmationActive(true)
                }
            } catch (error) {
                toast.error(error?.response?.data?.msg);
                setIsLoading(false);
            }
        },
    });

    useEffect(() => {
        if (formik.values.mailingZip && formik.values.mailingZip.length === 5) {
            async function getData() {
                const response = await Axios.get(`${BASE_URL}/api/zipCode/getByZipCode?zipCode=${formik.values.mailingZip}`);
                const data = response?.data?.data;
                formik.setFieldValue("mailingCity", data?.city);
                formik.setFieldValue("mailingState", data?.abbreviation);  
                setParentDetails(prev=>({ 
                    ...prev, 
                    "mailingCity":data?.city, 
                    "mailingState":data?.abbreviation
               }))
            }
            getData();
        }
    }, [formik.values.mailingZip]);

    useEffect(() => {
        // if (formik.values.isPoBoxAddress) {
        if (formik.values.poBoxZip && formik.values.poBoxZip.length === 5) {
            async function getData() {
                const response = await Axios.get(`${BASE_URL}/api/zipCode/getByZipCode?zipCode=${formik.values.poBoxZip}`);
                const data = response?.data?.data;
                formik.setFieldValue("poBoxCity", data?.city);
                formik.setFieldValue("poBoxState", data?.abbreviation);  
                    setParentDetails(prev=>({ 
                         ...prev, 
                         "poBoxCity":data?.city, 
                         "poBoxState":data?.abbreviation
                    }))
            
            }
            getData();
        }
        // }
    }, [formik.values.isPoBoxAddress, formik.values.poBoxZip]);

    const handleSame = () => {
        formik.setFieldValue("isSameServiceAddress", true);
        formik.setFieldValue("isNotSameServiceAddress", false);
        formik.setFieldValue("isPoBoxAddress", false);  
         
            setParentDetails(prev=>({ 
                 ...prev, 
                 "isSameServiceAddress":true, 
                 "isNotSameServiceAddress":false, 
                 "isPoBoxAddress":false 
            }))
      
        setIsSame(true);
        setIsDifferent(false);
        setIsPoBox(false);
    };

    const handleDifferent = () => {
        formik.setFieldValue("isNotSameServiceAddress", true);
        formik.setFieldValue("isSameServiceAddress", false);
        formik.setFieldValue("isPoBoxAddress", false);     
        setParentDetails(prev=>({ 
            ...prev, 
            "isSameServiceAddress":false, 
            "isNotSameServiceAddress":true, 
            "isPoBoxAddress":false 
       }))
        setIsSame(false);
        setIsDifferent(true);
        setIsPoBox(false);
    };

    const handlePobox = () => {
        formik.setFieldValue("isPoBoxAddress", true);
        formik.setFieldValue("isSameServiceAddress", false);
        formik.setFieldValue("isNotSameServiceAddress", false);
        setParentDetails(prev=>({ 
            ...prev, 
            "isSameServiceAddress":false, 
            "isNotSameServiceAddress":false, 
            "isPoBoxAddress":true 
       }))
        setIsSame(false);
        setIsDifferent(false);
        setIsPoBox(true);
    };

    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };


    useEffect(() => {
        if (isDifferent) {
            setConfrimAddress("different");
        } else if (isPoBox) {
            setConfrimAddress("pobox");
        }
    }, [isDifferent, isPoBox]);

    return (
        <>
            <ToastContainer />
            <form onSubmit={formik.handleSubmit}>
                {/* <div>
                    <h5 className="font-bold">ENROLLMENT ID: {enrollment_id}</h5>
                </div> */}

                <br></br>
                <p className="fname" style={{ marginTop: "-2px" }}>
                    WHAT IS YOUR HOME ADDRESS?
                </p>
                <p className="para">Please provide the address at which you will receive service; P.O. Box addresses are not acceptable.</p>

                <div className="flex flex-wrap flex-row justify-content-between">
                    <div className="calendar_field">
                        <p className="field_label ">
                            Address 1 <span style={{ color: "red" }}>*</span>
                        </p>
                        <InputText type="text" value={formik.values.address1} name="address1" onChange={(e)=>{ 
                              formik.setFieldValue("address1", e.target.value); 
                              setParentDetails(prev=>({ 
                                   ...prev, 
                                   "address1":e.target.value
                              }))
                        }} onBlur={formik.handleBlur} className="w-full" minLength={10} autoComplete="new-password" />
                        {formik.touched.address1 && formik.errors.address1 ? (
                            <p className="field_label" style={{ color: "red" }}>
                                {formik.errors.address1}
                            </p>
                        ) : null}
                    </div>
                    <div className="calendar_field">
                        <p className="field_label">Address 2</p>
                        <InputText type="text" value={formik.values.address2} name="address2" onChange={(e)=>{ 
                              formik.setFieldValue("address2", e.target.value); 
                              setParentDetails(prev=>({ 
                                   ...prev, 
                                   "address2":e.target.value
                              }))
                        }} onBlur={formik.handleBlur} className="w-full" autoComplete="new-password" />
                    </div>
                    <div className="calendar_field">
                        <p className="field_label">Google Auto Complete Address</p>
                        <GooglePlacesAutocomplete
                            apiKey="AIzaSyDa1KFekZkev2CAqrcrU_nYDe_1jC-PHA0"
                            selectProps={{
                                onChange: (e) => {/*handleAddressChange(e)*/},
                            }}
                        />
                    </div>

                    <div className="calendar_field">
                        <p className="field_label mt-4">
                            City <FontAwesomeIcon className="disable-icon-color icon-size" />{" "}
                        </p>
                        <InputText type="text" value={formik.values.city} name="city" className="w-full disable-color" onChange={(e)=>{ 
                              formik.setFieldValue("city", e.target.value); 
                              setParentDetails(prev=>({ 
                                   ...prev, 
                                   "city":e.target.value
                              }))
                        }} onBlur={formik.handleBlur} />
                    </div>
                    <div className="calendar_field">
                        <p className="field_label mt-4">
                            State <FontAwesomeIcon className="disable-icon-color icon-size" icon={parentDetails?.izZipVerified ? faBan : false} />
                        </p>
                        <InputText type="text" onBlur={formik.handleBlur} onChange={(e)=>{ 
                              formik.setFieldValue("state", e.target.value); 
                              setParentDetails(prev=>({ 
                                   ...prev, 
                                   "state":e.target.value
                              }))
                        }} disabled={parentDetails?.izZipVerified === true ? true : false} value={formik.values.state} name="state" className="w-full disable-color" />
                    </div>
                    <div className="calendar_field">
                        <p className="field_label mt-4">
                            Zip Code <FontAwesomeIcon className="disable-icon-color icon-size" icon={faBan} />
                        </p>
                        <InputText disabled value={formik.values.zip} name="zip" onChange={(e)=>{ 
                              formik.setFieldValue("zip", e.target.value); 
                              setParentDetails(prev=>({ 
                                   ...prev, 
                                   "zip":e.target.value
                              }))
                        }} onBlur={formik.handleBlur} className="w-full disable-color" />
                    </div>
                </div>
                <div className="calendar_field1">
                    <p className="field_label" style={{ fontWeight: "600" }}>
                        Is Your Mailling Address?
                    </p>
                </div>

                <div className="flex flex-wrap mt-1">
                    <div className="mr-3 flex alignitem-center">
                        <RadioButton inputId="confrimAddress" name="address" value="same" onClick={handleSame} onChange={(e) => setConfrimAddress(e.value)} checked={confrimAddress === "same"} />
                        <label htmlFor="sameAdress" className="ml-2" style={{ color: "#A0A0A0", fontWeight: "400" }}>
                            Same As Service Address
                        </label>
                    </div>
                    <div className="mr-3 flex alignitem-center">
                        <RadioButton inputId="confrimAddress" name="address" value="different" onClick={handleDifferent} onChange={(e) => setConfrimAddress(e.value)} checked={confrimAddress === "different"} />
                        <label htmlFor="differentAddress" className="ml-2" style={{ color: "#A0A0A0", fontWeight: "400" }}>
                            Different from Service Address
                        </label>
                    </div>
                    <div className="mr-3 flex alignitem-center">
                        <RadioButton inputId="confrimAddress" name="address" value="pobox" onClick={handlePobox} onChange={(e) => setConfrimAddress(e.value)} checked={confrimAddress === "pobox"} />
                        <label htmlFor="poboxAddress" className="ml-2" style={{ color: "#A0A0A0", fontWeight: "400" }}>
                            My mailing address is a PO BOX
                        </label>
                    </div>
                </div>

                {isDifferent && (
                    <>
                        <div className="field_label mt-5">Mailing Address</div>
                        <div className="flex flex-wrap flex-row justify-content-left">
                            <div className="calendar_field  ">
                                <label className="field_label mb-2">
                                    Address 1 <span className="steric">*</span>
                                </label>
                                <InputText id="mailingAddress1" value={formik.values.mailingAddress1} onChange={(e)=>{ 
                              formik.setFieldValue("mailingAddress1", e.target.value); 
                              setParentDetails(prev=>({ 
                                   ...prev, 
                                   "mailingAddress1":e.target.value
                              }))
                        }} className={classNames({ "p-invalid": isFormFieldValid("mailingAddress1") }, "input_text", "w-full")} autoComplete="new-password" />
                                {getFormErrorMessage("mailingAddress1")}
                            </div>
                            <div className="calendar_field space">
                                <label className="field_label mb-2"> Address 2 </label>
                                <InputText id="mailingAddress2" value={formik.values.mailingAddress2} onChange={(e)=>{ 
                              formik.setFieldValue("mailingAddress2", e.target.value); 
                              setParentDetails(prev=>({ 
                                   ...prev, 
                                   "mailingAddress2":e.target.value
                              }))
                        }} autoComplete="new-password" className="w-full" />
                            </div>

                            <div className="calendar_field space">
                                <label className="field_label  mt-2">
                                    City <FontAwesomeIcon className="disable-icon-color icon-size" icon={faBan} />{" "}
                                </label>
                                <InputText id="mailingCity" onChange={(e)=>{ 
                              formik.setFieldValue("mailingCity", e.target.value); 
                              setParentDetails(prev=>({ 
                                   ...prev, 
                                   "mailingCity":e.target.value
                              }))
                        }}  value={formik.values.mailingCity} disabled className="disable-color w-full" />
                            </div>
                            <div className="calendar_field space">
                                <label className="field_label mt-2">
                                    State <FontAwesomeIcon className="disable-icon-color icon-size" icon={faBan} />{" "}
                                </label>
                                <InputText id="mailingState"  value={formik.values.mailingState} onChange={(e)=>{ 
                              formik.setFieldValue("mailingState", e.target.value); 
                              setParentDetails(prev=>({ 
                                   ...prev, 
                                   "mailingState":e.target.value
                              }))
                        }} disabled className="disable-color w-full mt-2" />
                            </div>
                            <div className="calendar_field space " style={{ marginBottom: "3rem" }}>
                                <label className="field_label mt-2 ">
                                    Zip Code <span className="steric">*</span>
                                </label>
                                <InputText id="mailingZip" value={formik.values.mailingZip}  
                                   onChange={(e)=>{ 
                                    formik.setFieldValue("mailingZip", e.target.value); 
                                    setParentDetails(prev=>({ 
                                         ...prev, 
                                         "mailingZip":e.target.value
                                    }))
                              }}
                                  className={classNames({ "p-invalid": isFormFieldValid("mailingZip") }, "w-full", "mt-2")} keyfilter={/^\d{0,5}$/} maxLength={5} />
                                {getFormErrorMessage("mailingZip")}
                            </div>
                        </div>
                    </>
                )}
                {isPoBox && (
                    <>
                        <div className="p-fluid formgrid grid mt-5">
                            <div className="field col-12 md:col-3">
                                <label className="field_label">
                                    PO Box No <span className="steric">*</span>
                                </label>
                                <InputText id="PoBoxAddress" value={formik.values.PoBoxAddress}  onChange={(e)=>{ 
                              formik.setFieldValue("PoBoxAddress", e.target.value); 
                              setParentDetails(prev=>({ 
                                   ...prev, 
                                   "PoBoxAddress":e.target.value
                              }))
                        }}  className={classNames({ "p-invalid": isFormFieldValid("PoBoxAddress") }, "input_text")} keyfilter={/^[0-9]*$/} autoComplete="new-password" />
                                {getFormErrorMessage("PoBoxAddress")}
                            </div>

                            <div className="field col-12 md:col-3">
                                <label className="field_label">
                                    City <FontAwesomeIcon className="disable-icon-color icon-size" icon={faBan} />{" "}
                                </label>
                                <InputText id="poBoxCity" value={formik.values.poBoxCity} onChange={(e)=>{ 
                              formik.setFieldValue("poBoxCity", e.target.value); 
                              setParentDetails(prev=>({ 
                                   ...prev, 
                                   "poBoxCity":e.target.value
                              }))
                        }} className="disable-color" disabled />
                            </div>
                            <div className="field col-12 md:col-3">
                                <label className="field_label">
                                    <p>
                                        State <FontAwesomeIcon className="disable-icon-color icon-size" icon={faBan} />{" "}
                                    </p>
                                </label>
                                <InputText id="poBoxState" value={formik.values.poBoxState} onChange={(e)=>{ 
                              formik.setFieldValue("poBoxState", e.target.value); 
                              setParentDetails(prev=>({ 
                                   ...prev, 
                                   "poBoxState":e.target.value
                              }))
                        }} disabled className="disable-color" />
                            </div>
                            <div className="field col-12 md:col-3 mb-1">
                                <label className="field_label">
                                    Zip Code <span className="steric">*</span>
                                </label>
                                <InputText id="poBoxZip" value={formik.values.poBoxZip} onChange={(e)=>{ 
                              formik.setFieldValue("poBoxZip", e.target.value); 
                              setParentDetails(prev=>({ 
                                   ...prev, 
                                   "poBoxZip":e.target.value
                              }))
                        }} maxLength={5} keyfilter={/^[0-9]*$/} />
                            </div>
                        </div>
                    </>
                )}
                <div>
                    <div className="flex w-full flex-row justify-content-end align-items-center mb-2  ">
                        {/* <div style={{ marginLeft: "80%", marginTop: "2rem" }}>
                        <Button className="btn" label="Back" type="button" onClick={handleBack} />
                    </div> */}
                        <div  className="flex flex-wrap flex-row justify-content-left">
                            <Button className="btn " label="Back" type="button" onClick={()=>{ 
                                 setPersonalInfoActive(true) 
                                 setAddressActive(false)
                            }} />
                            <Button type="submit"  className="btn ml-1" label="Continue"    icon={isLoading === true ? "pi pi-spin pi-spinner " : ""} disabled={isLoading} />
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
};

export default Address;
