import React, { useEffect, useRef, useState } from "react";
import "./Main_Add_Service.css";
import InitiateEnrollment from "./components/Initiation/InitiationMain";
import PersonalInfo from "./components/PersonalInfo/PersonalInfo";
import Address from "./components/Address/Address";
import Agree from "./components/Confirmation/Confirmation";
import PaymentScreen from "./components/PaymentScreen/PaymentScreen";
import Axios from "axios";
import Preview from "./components/Preview/Preview";
import { Toast } from "primereact/toast";
import { ProgressSpinner } from "primereact/progressspinner";
export default function AddServiceMain({isLinkedAccountIncomplete,setIsLinkedAccountIncomplete}) {
    let toast = useRef();
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    const [initiateActive, setInitiateActive] = useState(true);
    const [personalInfoActive, setPersonalInfoActive] = useState(false);
    const [addressActive, setAddressActive] = useState(false);
    let selectedId = JSON.parse(localStorage.getItem("selectedId"));
    const [confirmationActive, setConfirmationActive] = useState(false);  
    const [checked,setChecked]=useState(isLinkedAccountIncomplete !== "" ? isLinkedAccountIncomplete?.shippingLabelDiscount:"" )
    const [paymentScreenActive, setPaymentScreenActive] = useState(false);
    const [previewScreenActive, setPreviewScreenActive] = useState(false);
    const [billingModelSelected, setBillingModelSelected] = useState("");
    const [currentBillingModel, setCurrentBillingModel] = useState();
    const [parentDetails, setParentDetails] = useState();   
    const [additionalsDiscounts,setAdditionalDiscounts]=useState({ 
        additional:[],  
         setted:false,
        discounts:[]
    })    
    
    const [parentCurrentContact, setParentCurrentContact] = useState();
    const [initiationData, setInitiationData] = useState();
    const [paymentScreenDetails, setPaymentScreenDetails] = useState({});
    const [currentAllPlans, setCurrentAllPlans] = useState();
    const [currentAllBillingConfig, setCurrentAllBillingConfig] = useState("");
    const [paymentDone, setPaymentDone] = useState(false);
    const [dataSendForInvoice, setDataSendForInvoice] = useState("");
    const [allpaymentinfo, setAllPaymentInfo] = useState("");
    const [proceed, setProceed] = useState(false);
    const [fetched, setFetched] = useState(false);
    const [carrierVal, setCarrierVal] = useState(isLinkedAccountIncomplete !== "" ? isLinkedAccountIncomplete?.carrierVal:"" );
    const [servicesVal, setServicesVal] = useState(isLinkedAccountIncomplete !== "" ? isLinkedAccountIncomplete?.servicesVal:"" );
    const [deliveryVal, setDeliveryVal] = useState(isLinkedAccountIncomplete !== "" ? isLinkedAccountIncomplete?.deliveryVal:"" );     

    const [checkLabelVal, setCheckLabelVal] = useState(isLinkedAccountIncomplete !== "" ? isLinkedAccountIncomplete?.labelSubmissionval:"" );
    const [generate, setGenerate] = useState(isLinkedAccountIncomplete !== "" ? isLinkedAccountIncomplete?.shippingLabelDiscount:"" );       
      const [prospectCases,setProspectCases]=useState({prospectWithInvoice:false,prospectWithoutInvoice:false})
    const [amount, setAmount] = useState(0);

    const [currentPlanSelect, setCurrentPlanSelect] = useState(paymentDone ? paymentScreenDetails?.currentPlanSelected : "");
    const [current, setCurrentSelect] = useState(paymentDone ? paymentScreenDetails?.inventorySelected : "");
    useEffect(() => {         
          if(isLinkedAccountIncomplete === ""){
        Axios.get(`${BASE_URL}/api/user/userDetails?userId=${selectedId}`)
            .then((res) => {
                setParentDetails(res?.data?.data);
                setFetched(true);
                setParentCurrentContact(res?.data?.data?.contact);
                if (res?.data?.data?.relationWithParent !== undefined) {
                    setProceed(false);

                    toast.current.show({ severity: "error", summary: "Info", detail: "You Can't Create Child Account Of A Child Account" });
                } else {
                    setProceed(true);   
                   
                }
            })
            .catch((err) => {}); 
        } 
        else{       
            const loginRes = localStorage.getItem("userData");
            const parseLoginRes = JSON.parse(loginRes); 
                Axios.get(`${BASE_URL}/api/billingModel/all?serviceProvider=${parseLoginRes?.company}`)
                    .then((res) => {
                   
                       
                        let billingmodels = res?.data?.data;
                        for (let i = 0; i < billingmodels.length; i++) {
                            if (billingmodels[i].billingModel === "PREPAID" && isLinkedAccountIncomplete?.accountType === "Prepaid") {
                             setCurrentBillingModel(billingmodels[i].billingModel)     
                             setBillingModelSelected(billingmodels[i]._id)                        
                            }
                            else if (billingmodels[i].billingModel === "POSTPAID" && isLinkedAccountIncomplete?.accountType === "Postpaid") {
                                
                                setCurrentBillingModel(billingmodels[i].billingModel)     
                             setBillingModelSelected(billingmodels[i]._id) 
                            }
                        }
                    
                   
            
            setProceed(true)    
            setParentDetails(isLinkedAccountIncomplete) 
            setInitiationData(isLinkedAccountIncomplete)
            setFetched(true)  
            setInitiateActive(false)
            setPersonalInfoActive(true)   
        })
        .catch((err) => { });
        }  
         return ()=>{ 
            setIsLinkedAccountIncomplete("")
         }
    }, []);
    return (
        <div className="linkedmain">
            {!fetched && !proceed ? (
                !fetched ? (
                    <div>
                        <div style={{ marginTop: "40px" }} className="mt-4 flex justify-content-center">
                            <ProgressSpinner />
                        </div>
                    </div>
                ) : (
                    <h1>Can't Create Child Account Of A Child Account</h1>
                )
            ) : fetched && proceed ? (
                <>
                    <div className="flex flex-wrap gap4 flex-row justify-content-left gap-4">
                        <p className={`linkedmainheader ${initiateActive ? "linkedmainheaderactive" : ""}`}>Initiation</p>
                        <p className={`linkedmainheader ${personalInfoActive ? "linkedmainheaderactive" : ""}`}>Personal Info</p>
                        <p className={`linkedmainheader ${addressActive ? "linkedmainheaderactive" : ""}`}>Address</p>
                        <p className={`linkedmainheader ${confirmationActive ? "linkedmainheaderactive" : ""}`}>Confirmation</p>

                        <p className={`linkedmainheader ${paymentScreenActive ? "linkedmainheaderactive" : ""}`}>Product</p>

                        <p className={`linkedmainheader ${previewScreenActive ? "linkedmainheaderactive" : ""}`}>Preview</p>
                    </div>
                    {initiateActive ? (
                        <InitiateEnrollment
                            setParentDetails={setParentDetails}
                            setInitiationData={setInitiationData}
                            parentDetails={parentDetails}
                            setCurrentBillingModel={setCurrentBillingModel}
                            billingModelSelected={billingModelSelected}
                            setBillingModelSelected={setBillingModelSelected}
                            setPersonalInfoActive={setPersonalInfoActive}
                            setInitiateActive={setInitiateActive}
                        />
                    ) : personalInfoActive ? (
                        <PersonalInfo    
                        isLinkedAccountIncomplete={isLinkedAccountIncomplete}
                            parentCurrentContact={parentCurrentContact}
                            setParentDetails={setParentDetails}
                            initiationData={initiationData}
                            currentBillingModel={currentBillingModel}
                            parentDetails={parentDetails}
                            setInitiateActive={setInitiateActive}
                            setPersonalInfoActive={setPersonalInfoActive}
                            setAddressActive={setAddressActive}
                        />
                    ) : addressActive ? (
                        <Address isLinkedAccountIncomplete={isLinkedAccountIncomplete} parentDetails={parentDetails} initiationData={initiationData} setParentDetails={setParentDetails} setAddressActive={setAddressActive} setPersonalInfoActive={setPersonalInfoActive} setConfirmationActive={setConfirmationActive} />
                    ) : confirmationActive ? (
                        <Agree setParentDetails={setParentDetails}  setPaymentScreenActive={setPaymentScreenActive} parentDetails={parentDetails} initiationData={initiationData} setAddressActive={setAddressActive}   setPreviewScreenActive={setPreviewScreenActive} isLinkedAccountIncomplete={isLinkedAccountIncomplete} setConfirmationActive={setConfirmationActive} />
                    ) : paymentScreenActive ? (
                        <PaymentScreen
                            currentAllPlans={currentAllPlans} 
                            prospectCases={prospectCases} 
                            setProspectCases={setProspectCases}
                            setCurrentAllBillingConfig={setCurrentAllBillingConfig}
                            setCurrentAllPlans={setCurrentAllPlans}
                            setAllPaymentInfo={setAllPaymentInfo}
                            setDataSendForInvoice={setDataSendForInvoice}
                            paymentScreenDetails={paymentScreenDetails}
                            paymentDone={paymentDone}            
                            setPaymentDone={setPaymentDone}
                            initiationData={initiationData}
                            parentDetails={parentDetails}
                            setPaymentScreenActive={setPaymentScreenActive}
                            currentBillingModel={currentBillingModel}
                            setConfirmationActive={setConfirmationActive}
                            billingModelSelected={billingModelSelected}
                            setPreviewScreenActive={setPreviewScreenActive}
                            setPaymentScreenDetails={setPaymentScreenDetails}
                            carrierVal={carrierVal}
                            setCarrierVal={setCarrierVal}
                            servicesVal={servicesVal}
                            setServicesVal={setServicesVal}
                            deliveryVal={deliveryVal}
                            setDeliveryVal={setDeliveryVal}  
                            checkLabelVal={checkLabelVal}
                            setCheckLabelVal={setCheckLabelVal}
                            checked={checked}
                            setChecked={setChecked}
                            generate={generate}
                            setGenerate={setGenerate}
                            currentPlanSelect={currentPlanSelect}
                            setCurrentPlanSelect={setCurrentPlanSelect}
                            current={current}   
                            additionalsDiscounts={additionalsDiscounts} 
                            setAdditionalDiscounts={setAdditionalDiscounts}
                            setCurrentSelect={setCurrentSelect}
                            amount={amount}
                            setAmount={setAmount}
                            // product={product}
                            // setProduct={setProduct}
                            // current={current}
                            // setCurrentSelect={setCurrentSelect}
                            // currentPlanSelect={currentPlanSelect}
                            // setCurrentPlanSelect={setCurrentPlanSelect}
                        />
                    ) : previewScreenActive ? (
                        <Preview
                            paymentDone={paymentDone}   
                            prospectCases={prospectCases}     
                            isLinkedAccountIncomplete={isLinkedAccountIncomplete}
                             setConfirmationActive={setConfirmationActive}
                            currentAllBillingConfig={currentAllBillingConfig}
                            currentAllPlans={currentAllPlans}
                            setPreviewScreenActive={setPreviewScreenActive}
                            setPaymentScreenActive={setPaymentScreenActive}
                            initiationData={initiationData}
                            parentDetails={parentDetails}
                            paymentScreenDetails={paymentScreenDetails}
                        />
                    ) : (
                        ""
                    )}
                </>
            ) : (
                <p className="text-center p-error">Can't Create Child Of A Child Component</p>
            )}
            <Toast ref={toast} />
        </div>
    );
}
