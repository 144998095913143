import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import DialogeForAuthPayment from "../DialogeForAuthPayment";
import PaymentStripModule from "../dialog/stripe_payment";
import momenttz from "moment-timezone";
import moment from "moment";
import ShowVoid from "./ShowVoid";
import ShowAdjustAmount from "./ShowAdjustAmount";
import ShowLateFee from "./ShowLateFee";
import "../css/invoicetable.css";
import CustomerInvoicePrepaidUpdated from "../customer_invoice/customer_invoice_prepaid_updated";
import Axios from "axios";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const InvoiceTable = ({ setRefresh, refresh, userDetails, invoiceData }) => {
    const cardData = invoiceData;
    const [isLoading, setIsLoading] = useState(false);
    const [singleInvoiceData, setInvoiceData] = useState();
    const [dialogeForAuthPayment, setdialogeForAuthPayment] = useState(false);
    const [invoiceId, setInvoiceId] = useState();
    const [dueAmount, setdueAmount] = useState();
    const [invoiceDownload, setInvoiceDownload] = useState(false);
    const [selectedInvoice, setSelectedInvoice] = useState();
    const [selectMange, setSelectManage] = useState({});
    const [showVoid, setShowVoid] = useState(false);
    const [showLateFee, setShowLateFee] = useState(false);
    const [showAdjustAmount, setShowAdjustAmount] = useState(false);
    const [paymentDialogVisibility, setPaymentDialogVisibility] = useState(false);

    const rowClassName = (rowData) => {
        if (rowData.invoiceVoid) {
            return "void-invoice";
        }
        if (rowData.invoiceStatus === "Paid") {
            return "text-blue-400";
        }
        if (rowData.invoiceStatus === "Partially Paid" || rowData.invoiceStatus === "Partial") {
            return "partialpaidinvoice";
        }
        return "unpaid-invoice-red";
    };

    const loginRes = localStorage.getItem("userData");
    const parseLoginRes = JSON.parse(loginRes);
    // const companyNameToCapital = companyName.toUpperCase();

    const options = [
        { label: "Void", value: "Void" },
        { label: "Late fee", value: "lateFee" },
        { label: "Adjust Invoice", value: "AdjustInvoice" },
    ];
    const optionsForUnpaid = [
        { label: "Void", value: "Void" },
        { label: "Late fee", value: "lateFee" },
        { label: "Adjust Invoice", value: "AdjustInvoice" },
    ];
    const formatDate = (dateString) => {
        if (dateString.length < 2) {
            return <p>- / -/ -</p>;
        }
        const options = { year: "numeric", month: "2-digit", day: "2-digit" };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    useEffect(() => {
        if (selectMange[selectedInvoice?._id] === "Void") {
            setShowVoid(true);
        } else if (selectMange[selectedInvoice?._id] === "AdjustInvoice") {
            setShowAdjustAmount(true);
        } else if (selectMange[selectedInvoice?._id] === "lateFee") {
            setShowLateFee(true);
        }
    }, [selectMange, selectedInvoice]);

    const handleDropdownChange = (e, rowData) => {
        setSelectManage((prev) => ({ ...prev, [rowData._id]: e.value }));
        setSelectedInvoice(rowData);
    };
    const getInvoiceData = async (rowData) => {
        const res = await Axios.get(`${BASE_URL}/api/web/invoices/getinvoicebyid?invoiceId=${rowData?._id}`);
        if (res?.status === 200 || res?.status === 201) {
            setInvoiceData(res?.data?.data);
            setInvoiceDownload((prev) => !prev);
        }
    };
    const transIdBodyTemplate = (rowData) => {
        return (
            <div>
                {rowData.transId.map((id, index) => (
                    <div key={index}>{id} </div>
                ))}
            </div>
        );
    };
    return (
        <div className="relative">
            <Dialog
                draggable={false}
                visible={showVoid}
                header={`Void Invoice`}
                style={{ width: "40vw" }}
                onHide={() => {
                    setShowVoid(false);
                    setSelectManage("");
                }}
            >
                <ShowVoid  setSelectManage={ setSelectManage} singleInvoiceData={selectedInvoice} setShowVoid={setShowVoid} setRefresh={setRefresh} userDetails={userDetails} />
            </Dialog>
            <Dialog
                draggable={false}
                visible={showAdjustAmount}
                header={`Adjust Amount`}
                style={{ width: "40vw" }}
                onHide={() => {
                    setShowAdjustAmount(false);
                    setSelectManage("");
                }}
            >
                <ShowAdjustAmount setSelectManage={setSelectManage} singleInvoiceData={selectedInvoice} setShowAdjustAmount={setShowAdjustAmount} setRefresh={setRefresh} userDetails={userDetails} />
            </Dialog>
            <Dialog
                draggable={false}
                visible={showLateFee}
                header={`Waive late fee`}
                style={{ width: "40vw" }}
                onHide={() => {
                    setShowLateFee(false);
                    setSelectManage("");
                }}
            >
                <ShowLateFee      setSelectManage={ setSelectManage} singleInvoiceData={selectedInvoice} setShowLateFee={setShowLateFee} setRefresh={setRefresh} userDetails={userDetails} />
            </Dialog>
            <div className="">
                <Dialog header={"Payment"} visible={dialogeForAuthPayment} style={{ width: "50vw" }} onHide={() => setdialogeForAuthPayment(false)}>
                    <DialogeForAuthPayment setRefresh={setRefresh} dueAmount={dueAmount} setdialogeForAuthPayment={setdialogeForAuthPayment} invoiceId={invoiceId} userDetails={userDetails} invoiceData={invoiceData} />
                </Dialog>
                <Dialog className="stripe-dialog-width" header="Stripe Payment" visible={paymentDialogVisibility} onHide={() => setPaymentDialogVisibility(false)}>
                    <PaymentStripModule setRefresh={setRefresh} dueAmount={dueAmount} userDetails={userDetails} setPaymentDialogVisibility={setPaymentDialogVisibility} invoiceId={invoiceId} />
                </Dialog>
                <div className=" mt-4 mb-3">
                    <p className="m-0 text-lg font-bold " style={{ color: "red" }}>
                        •Rows in red color are Unpaid Invoices
                    </p>
                    <p className="text-lg mt-2 font-bold text-blue-400">•Rows in blue color are Paid Invoices</p>
                    <p style={{ color: "rgba(250, 129, 22,1)" }} className="text-lg mt-2 font-bold">
                        •Rows in orange color are partially Paid Invoices
                    </p>
                </div>

                <DataTable size="small" style={{ fontWeight: "600" }} className="mt-4" stripedRows resizableColumns emptyMessage="No Invoice found." value={cardData} rowClassName={rowClassName}>
                    <Column field="invoiceNo" header="Invoice No." body={(rowData) => <span style={{ cursor: "pointer" }}>{rowData?.invoiceNo}</span>} />
                    <Column field="invoiceType" header="Invoice Type" />
                    <Column field="invoiceOneTimeCharges" header="Invoice One Time Charges (A)" body={(rowData) => parseFloat(rowData.invoiceOneTimeCharges).toFixed(2)} />
                    <Column field="planCharges" header="Plan Charges (B)" />
                    <Column field="additionalCharges" header="Additional Charges (C)" body={(rowData) => rowData?.additionalCharges.map((charge, index) => `${charge.name}:${charge.amount}${index + 1 === rowData?.additionalCharges.length ? "" : ", "}`).join("")} />
                    <Column field="discount" header="Discounts (D)" body={(rowData) => rowData?.discount.map((discount, index) => `${discount.name}:${discount.amount}${index + 1 === rowData?.discount.length ? "" : ", "}`).join("")} />
                    <Column
                        field="shipmentCost.amount"
                        body={(rowData) => {
                            return <p>{rowData?.shipmentCost?.amount !== undefined && rowData?.shipmentCost?.amount !== "NaN" && rowData?.shipmentCost?.amount !== "" ? rowData?.shipmentCost?.amount : "0.00"}</p>;
                        }}
                        header="Shipment Cost"
                    />
                    <Column field="lateFee" header="Late Fee" />
                    <Column field="taxe" header="Taxes" />
                    <Column field="amountPaid" header="Amount Paid" body={(rowData) => (rowData?.amountPaid !== undefined && rowData?.amountPaid !== "NaN" && rowData?.amountPaid !== "" ? parseFloat(rowData?.amountPaid).toFixed(2) : "0.00")} />
                    <Column field="currentBalance" header="Current Bill" body={(rowData) => (rowData?.currentBalance !== undefined && rowData?.currentBalance !== "NaN" && rowData?.currentBalance !== "" ? parseFloat(rowData?.currentBalance).toFixed(2) : "0.00")} />
                    <Column field="previousBalance" body={(rowData) => (rowData?.previousBalance !== undefined && rowData?.previousBalance !== "NaN" && rowData?.previousBalance !== "" ? parseFloat(rowData?.previousBalance).toFixed(2) : "0.00")} header="Previous Balance" />
                    <Column
                        field="dueAmount"
                        body={(rowData) => {
                            return <p>{rowData?.dueAmount !== "NaN" && rowData?.dueAmount !== null && rowData?.dueAmount !== "" && rowData?.dueAmount !== undefined ? rowData?.dueAmount : "0.00"}</p>;
                        }}
                        header="Total Due"
                    />
                    <Column field="invoiceCreateDate" header="Created Date" body={(rowData) => momenttz.tz(rowData?.createdAt, "America/New_York").format("MM-DD-YYYY")} />
                    {/* console.log("formatted date", moment.tz(data?.[0]?.createdAt, "America/New_York").format("MM-DD-YYYY")); */}
                    <Column field="invoiceDueDate" header="Due Date" body={(rowData) => (rowData?.invoiceDueDate !== "-" ? moment(rowData?.invoiceDueDate, "M-D-YYYY").format("MM-DD-YYYY") : "-")} />
                    <Column field="createdBy.name" header="Created By" />
                    <Column field="invoicePaymentMethod" header="Paid Using" body={(rowData) => (rowData?.invoicePaymentMethod?.includes("Skip") ? "" : rowData.invoicePaymentMethod)} />
                    <Column field="invoiceStatus" header="Invoice Status" />
                    <Column field="transId" header="Confirmation NO" body={transIdBodyTemplate} />
                    <Column
                        field="Invoice_Pdf"
                        body={(rowData) => (
                            <Button
                                style={{ fontWeight: "600" }}
                                className="  pr-2 pt-2 pl-3 pr-3 pb-2 border-none "
                                onClick={() => {
                                    getInvoiceData(rowData);
                                }}
                                disabled={isLoading}
                            >
                                Download
                            </Button>
                        )}
                        header="Invoice Pdf"
                    />
                    <Column
                        field="Action"
                        body={(rowData) => (
                            <Dropdown
                                style={{ fontWeight: "600" }}
                                className="pl-3 border-none text-white"
                                options={rowData?.invoiceStatus === "Unpaid" || rowData?.invoiceStatus === "Pending" ? optionsForUnpaid : options}
                                placeholder="Manage"
                                value={selectMange[rowData._id] || null}
                                onChange={(e) => handleDropdownChange(e, rowData)}
                                disabled={rowData.invoiceVoid}
                            />
                        )}
                        header="Action"
                    />
                </DataTable>
                <CustomerInvoicePrepaidUpdated userDetails={userDetails} invoiceDownload={invoiceDownload} invoiceData={singleInvoiceData} setIsLoading={setIsLoading} />
            </div>
        </div>
    );
};

export default InvoiceTable;
