import { Button } from "primereact/button";
import React, { useState } from "react"; import Axios from "axios";
import { ToastContainer, toast } from "react-toastify"; // Import ToastContainer and toast
import "react-toastify/dist/ReactToastify.css"; // Import toast styles

const BASE_URL = process.env.REACT_APP_BASE_URL;
const ShowConfrimMessage = ({ setChangeDueDate, setShowConfrimMessage, setRefresh, cpData, newDate }) => {

    const [isLoading, setIsLoading]= useState(false)
    const loginRes = localStorage.getItem("userData");
    const parseLoginRes = JSON.parse(loginRes);

    function handleNo() {
        setShowConfrimMessage(false)
        setChangeDueDate(false);

    }
    const handleYes = async () => {
        setIsLoading(true)
        const data = {
            customerId: cpData?._id,
            newDueDate: newDate,
            userId:parseLoginRes?._id
        };
      
        try {
            const resp = await Axios.patch(`${BASE_URL}/api/web/invoices/changeDueDate`, data);
            if (resp?.status == "200" || resp?.status == "201") {
                toast.success("Changed Successfully")
                setIsLoading(false)
                setRefresh(true)
                setTimeout(() => {
                    setShowConfrimMessage(false)
                    setChangeDueDate(false)
                }, 1000)
            }
        } catch (error) {
            toast.error(error?.response?.data?.msg);
            setIsLoading(false)
            setShowConfrimMessage(false)
           
        }
        setIsLoading(false)

    }
    return (
        <>
            <ToastContainer />
            <p>
                This action will change the due date on all of this customer's unpaid bills and upcoming bills. Are you sure you want to proceed?
            </p>
            <div className="confrimBtn">
                <Button label="NO" onClick={handleNo}  />
                <Button label="Yes" onClick={handleYes} disabled={isLoading} icon={isLoading === true ? "pi pi-spin pi-spinner " : ""} />
            </div>
        </>
    )
}
export default ShowConfrimMessage;