import React, { useEffect, useState } from "react"; 
import Axios from "axios";
import { useLocation } from "react-router-dom";        
import "./css/prepaid-order-main.css"
import ServiceAvailabilityPage from "./components/eligiblityForEnrollment/pages/service_availblity_page";
import EnrollmentFlowPage from "./components/eligiblityForEnrollment/pages/enrollment_flow_page";
import CommonLoaderBlue from "../../../../components/Loader/LoaderBlue";
export default function MainPrepaidOrders() {    
    const BASE_URL = process.env.REACT_APP_BASE_URL; 
    const loginRes = localStorage.getItem("userData");
    const parseLoginRes = JSON.parse(loginRes);   
    const location = useLocation();
    const [counter, setcounter] = useState(0);
    let fromIncomplete = localStorage.getItem("comingfromincomplete");   
    const [checkBilling,setCheckBilling]=useState(false)   
    const [billingCheckapicalled,setCheckbillingapicalled]=useState(false)
    const [zipVerified, setZipVerified] = useState(fromIncomplete || localStorage.getItem("comingforedit") ? true : false);
    useEffect(() => {    
        Axios.get(`${BASE_URL}/api/web/billing/StatusBillingModel`,{ 
           params:{ billingmodel:"PREPAID",  
            serviceProvider:parseLoginRes?.company 
           }
        }).then((res)=>{    
      
       setCheckBilling(res?.data?.success ===  "false" ? false :true)         
       setCheckbillingapicalled(true)   
        
        }).catch(err=>{ 
             
        })
          
        return () => {
            cleanLocalStorage();
        };
    }, []);       
    
    useEffect(() => {
        const handleRouteChange = (newLocation) => {
            if (newLocation.pathname === "/prepaid-newenrollment") {
                if (counter === 0) {
                } else {
                    setZipVerified(false);
                }
                setcounter((prev) => (prev = prev + 1));
            }
        };
        handleRouteChange(location);
        window.addEventListener("popstate", handleRouteChange);
        return () => {
            window.removeEventListener("popstate", handleRouteChange);
        };
    }, [location]);

    return <>{   

       !billingCheckapicalled  ?   
           <div className="main-container-loader flex flex-wrap  pt-4 pb-4 flex-row justify-content-center align-items-center">
        <CommonLoaderBlue/>   
           </div>
         : billingCheckapicalled && !checkBilling ?   <div className="main-container-error"><p  className="text-center text-red-500">Setup Billing Configuration For This Module</p>   </div> : billingCheckapicalled &&  !zipVerified   ? <ServiceAvailabilityPage setZipVerified={setZipVerified} /> : <EnrollmentFlowPage />
        
        }</>;
    // return (
    //     <>
    //         {/*!zipVerified ? <ServiceAvailabilityPage setZipVerified={setZipVerified} /> :*/} <EnrollmentFlowPage />
    //     </>
    // );
}
function cleanLocalStorage() {
    localStorage.removeItem("comingforedit");
    localStorage.removeItem("comingfromincomplete");
    localStorage.removeItem("paymentallinfo");
    localStorage.removeItem("prepaidbasicData");
    localStorage.removeItem("prepaidaddress");
    localStorage.removeItem("simpricing");
    localStorage.removeItem("devicepricing");
    localStorage.removeItem("prepaidcheckEligibility");
    localStorage.removeItem("prepaidagreeData");
    localStorage.removeItem("prepaidprogrammeId");
    localStorage.removeItem("comingfromincomplete");
    localStorage.removeItem("paymentmethod");
    localStorage.removeItem("paymentdetails");
    localStorage.removeItem("inventoryType");
    //Payment Status
    localStorage.removeItem("paymentstatus");

    localStorage.removeItem("stripeId");
    //Device local
    localStorage.removeItem("deviceadditional");
    localStorage.removeItem("deviceadditionaltotal");
    localStorage.removeItem("deviceadditionalfeaturearray");
    localStorage.removeItem("totaldevicediscount");
    localStorage.removeItem("devicediscountobjectarray");
    localStorage.removeItem("deviceplan");
    localStorage.removeItem("devicepricing");
    //SIM Local
    localStorage.removeItem("simadditional");
    localStorage.removeItem("simadditionaltotal");
    localStorage.removeItem("simadditionalfeaturearray");
    localStorage.removeItem("totalsimdiscount");
    localStorage.removeItem("simdiscountobjectarray");
    localStorage.removeItem("simplan");
    localStorage.removeItem("simpricing");
}
